import { Injectable, Injector        } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { SidebarRouteExtended   } from "@shared/models";
import { UserService } from "./auth/user.service";

import { TimeTrackingSidebarItems } from "../shared/sidebar/time-tracking-sidebar-items";
import { InvoicesSidebarItems     } from "../shared/sidebar/invoices-sidebar-items";
import { SessionStorageService    } from "./session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class SidebarStorageService {
  private navItemsSource = new BehaviorSubject<SidebarRouteExtended[]>(null);
  navItems = this.navItemsSource.asObservable();

  private openActivityReportsCountSource = new BehaviorSubject<number>(0);
  openActivityReportsCount = this.openActivityReportsCountSource.asObservable();

  private approvedReportsCountSource = new BehaviorSubject<number>(0);
  approvedReportsCount = this.approvedReportsCountSource.asObservable();

  private clarificationReportsCountSource = new BehaviorSubject<number>(0);
  clarificationReportsCount = this.clarificationReportsCountSource.asObservable();

  private failedERPCountSource = new BehaviorSubject<number>(0);
  failedERPCount = this.failedERPCountSource.asObservable();

  private activePhotoDocumentsCountSource = new BehaviorSubject<number>(0);
  activePhotoDocumentsCount = this.activePhotoDocumentsCountSource.asObservable();

  private unconfirmedEBSCountSource = new BehaviorSubject<number>(0);
  unconfirmedEBSCount = this.unconfirmedEBSCountSource.asObservable();

  private expiringSoonAssignmentsSource = new BehaviorSubject<number>(0);
  expiringSoonAssignmentsCount = this.expiringSoonAssignmentsSource.asObservable();

  private archivedInvoicesCountSource = new BehaviorSubject<number>(0);
  archivedInvoicesCount = this.archivedInvoicesCountSource.asObservable();

  private activeInvoicesCountSource = new BehaviorSubject<number>(0);
  activeInvoicesCount = this.activeInvoicesCountSource.asObservable();

  private approvedVacationRequestsCountSource = new BehaviorSubject<number>(0);
  approvedVacationRequestsCount = this.approvedVacationRequestsCountSource.asObservable();

  private awaitingVacationRequestsCountSource = new BehaviorSubject<number>(0);
  awaitingVacationRequestsCount = this.awaitingVacationRequestsCountSource.asObservable();

  private rejectedVacationRequestsSource = new BehaviorSubject<number>(0);
  rejectedVacationRequests = this.rejectedVacationRequestsSource.asObservable();

  private failedVacationRequestsSource = new BehaviorSubject<number>(0);
  failedVacationRequests = this.failedVacationRequestsSource.asObservable();

  private awaitingMileageMoneyReportsSource = new BehaviorSubject<number>(0);
  awaitingMileageMoneyReports = this.awaitingMileageMoneyReportsSource.asObservable();

  private sidebarStateSource = new BehaviorSubject<string>(null);
  sidebarState = this.sidebarStateSource.asObservable();

  constructor (
    private injector:    Injector,
    private userService: UserService
  ) { }

  changeApprovedReportsCount(             count: number): void { this.approvedReportsCountSource.next(count);          }
  changeOpenActivityReportsCount(         count: number): void { this.openActivityReportsCountSource.next(count);      }
  changeClarificationReportsCount(        count: number): void { this.clarificationReportsCountSource.next(count);     }
  changeFailedERPCount(                   count: number): void { this.failedERPCountSource.next(count);                }

  changeActivePhotoDocumentsCount(        count: number): void { this.activePhotoDocumentsCountSource.next(count);     }
  changeUnconfirmedEBSCount(              count: number): void { this.unconfirmedEBSCountSource.next(count);           }
  changeExpiringSoonAssignmentsCount(     count: number): void { this.expiringSoonAssignmentsSource.next(count);       }
  
  changeApprovedVacationRequestsCount(    count: number): void { this.approvedVacationRequestsCountSource.next(count); }
  changeAwaitingVacationRequestsCount(    count: number): void { this.awaitingVacationRequestsCountSource.next(count); }
  changeRejectedVacationRequestsCount(    count: number): void { this.rejectedVacationRequestsSource.next(count);      }
  changeFailedExportVacationRequestsCount(count: number): void { this.failedVacationRequestsSource.next(count);        }

  changeAwaitingMileageMoneyCount(        count: number): void { this.awaitingMileageMoneyReportsSource.next(count);   }

  changeArchivedInvoicesCount(            count: number): void { this.archivedInvoicesCountSource.next(count);         }
  changeActiveInvoicesCount(              count: number): void { this.activeInvoicesCountSource.next(count);           }

  get approvedReportsCountValue():             number { return this.approvedReportsCountSource.value;          }
  get openActivityReportsCountValue():         number { return this.openActivityReportsCountSource.value;      }
  get clarificationReportsCountValue():        number { return this.clarificationReportsCountSource.value;     }
  get failedERPCountValue():                   number { return this.failedERPCountSource.value;                }

  get activePhotoDocumentsCountValue():        number { return this.activePhotoDocumentsCountSource.value;     }
  get unconfirmedEBSCountValue():              number { return this.unconfirmedEBSCountSource.value;           }
  get expiringSoonAssignmentsValue():          number { return this.expiringSoonAssignmentsSource.value;       }

  get approvedVacationRequestsCountValue():    number { return this.approvedVacationRequestsCountSource.value; }
  get awaitingVacationRequestsCountValue():    number { return this.awaitingVacationRequestsCountSource.value; }
  get rejectedVacationRequestsCountValue():    number { return this.rejectedVacationRequestsSource.value;      }
  get failedVacationRequestsCountValue():      number { return this.failedVacationRequestsSource.value;        }

  get awaitingMileageMoneyReportsCountValue(): number { return this.awaitingMileageMoneyReportsSource.value;   }

  get archivedInvoicesCountValue():            number { return this.archivedInvoicesCountSource.value;         }
  get activeInvoicesCountValue():              number { return this.activeInvoicesCountSource.value;           }

  changeNavItems(navItems: SidebarRouteExtended[]): void {
    this.navItemsSource.next(navItems);
  }

  prepareTimeTrackingSidebar(): void {
    let tempNavItems = TimeTrackingSidebarItems.filter(item => this.filterByRole(item))
    .map(item => {
      if (item.children) item.children = item.children.filter(child => this.filterByRole(child)).filter(i => {
        if (!i.betaFlag) return true;
        return this.userService.currentUserValue?.features[i.betaFlag]?.length;
      });
      return item;
    });    
    tempNavItems = this.highlightNewFeatures(tempNavItems);
    this.changeNavItems(tempNavItems);
  }

  private filterByRole(item): boolean {
    if (!item.isCustomer && !item.isInternal) return true;
    else if (item.isCustomer) return item.isCustomer && this.userService.isCustomer;
    else if (item.isInternal) return item.isInternal && this.userService.isInternal;
  }

  private highlightNewFeatures(navItems: SidebarRouteExtended[]): SidebarRouteExtended[] {
    let sessionStorageService = this.injector.get(SessionStorageService);
    let list = navItems.map(i => ({...i}));

    if (this.userService.latestRelease && +(new Date(this.userService.latestRelease.released_at)) < +new Date() + 7*24*60*60*1000 && !sessionStorageService.checkReleasePassed(this.userService.latestRelease)) this.highlightFeature(list, 'releases');
    return list;
  }

  private highlightFeature(list: SidebarRouteExtended[], feature: string): void {
    let nav = list.find(i => i.path === feature);
    if (nav) nav.dot = true;
  }

  prepareInvoicesSidebar(): void {
    this.changeNavItems(InvoicesSidebarItems);
  }

  changeSidebar(app: string = null): void {
    if (!app || app === 'time-tracking') this.prepareTimeTrackingSidebar();
    else if    (app === 'invoices')      this.prepareInvoicesSidebar();
  }

  get sidebarItems(): SidebarRouteExtended[] {
    return this.navItemsSource.value;
  }

  get sidebarItemsSubscribe(): Observable<SidebarRouteExtended[]> {
    return this.navItems;
  }

  get navItemsMapped(): SidebarRouteExtended[] {
    let parent   = this.sidebarItems;
    let children = [...this.sidebarItems.filter(i => i.children).reduce((sum, val) => sum = [...sum, ...val.children], [])];
    return [...parent, ...children];
  }

  changeSidebarState(state: string): void {
    this.sidebarStateSource.next(state);
  }

  toggleSidebarState(): void {
    this.sidebarStateSource.next(this.sidebarStateSource.value === 'mini' ? 'full' : 'mini');
  }

}