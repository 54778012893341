import { EnvironmentInjector, inject, Injectable, runInInjectionContext } from '@angular/core';
import { Router } from '@angular/router';

import {
  UserService,
  SessionStorageService,
  SidebarStorageService,
  NotificationService,
  FailedErpService,
  ActivityReportsService,
  AssignmentService,
  PhotoDocumentService,
  InvoicesService,
  ActivityReportsCountersService,
  AssignmentsCountersService,
  InvoicesCountersService,
  PhotoDocumentsCountersService,
  VacationRequestListService,
  VacationRequestsCountersService,
  MileageMoneyListService,
  MileageMoneyCountersService
} from '@shared/services';

import { ActivityReportsCountersModel, ActivityReportsCountersTokenModel, TogglerModel, VacationRequestsCountersModel } from '@shared/models';

@Injectable()
export class CountersService {
  constructor (
    private router:                     Router,
    private injector:                   EnvironmentInjector,
    private activityReportsService:     ActivityReportsService,
    private failedErpService:           FailedErpService,
    private photoDocumentService:       PhotoDocumentService,
    private assignmentService:          AssignmentService,
    private vacationRequestListService: VacationRequestListService,
    private invoicesService:            InvoicesService,
    private mileageMoneyListService:    MileageMoneyListService,
    private sessionStorageService:      SessionStorageService,
    private sidebarStorageService:      SidebarStorageService,
    private userService:                UserService,
    private notificationService:        NotificationService
  ) { }

  checkForUpdates(newTokens, useCase: string, skipNotification: boolean = null): void {
    let oldTokens = this.sessionStorageService[`${useCase}CountersTokensValue`];
    if (oldTokens) {
      let newEntries     = {};
      let deletedEntries = {};

      for (const key in newTokens) { newEntries[key]     = newTokens[key].filter(nt => !nt.creator).filter(nt => !oldTokens[key].find(ot => nt.id === ot.id)); }
      for (const key in newTokens) { deletedEntries[key] = oldTokens[key].filter(nt => !newTokens[key].find(ot => nt.id === ot.id));                           }
      this.needUpdate(newEntries, deletedEntries, useCase, skipNotification);
    }
    this.sessionStorageService[`change${this.capitalize(useCase)}CountersTokens`](newTokens);
  }

  private needUpdate(newEntries, deletedEntries, useCase: string, skipNotification: boolean = null): void {
    let tab    = this.sessionStorageService.activeTabValue;
    let update = Object.values(newEntries).flat().length + Object.values(deletedEntries).flat().length;

    if (tab && update) {
      switch (useCase) {
        case 'activityReports':  { this.activityReportsNeedUpdate(newEntries,  deletedEntries, skipNotification); break; }
        case 'vacationRequests': { this.vacationRequestsNeedUpdate(newEntries, deletedEntries);                   break; }
        case 'photoDocuments':
        case 'invoices':
        case 'EBS':
        case 'assignments':
        case 'mileageMoneyReports':
          this.generalUpdate(newEntries, deletedEntries, useCase);
          break;
        default:
          break;
      }
    }
  }

  private generalUpdate(newEntries, deletedEntries, useCase) {
    let update = this.getEntriesLength(newEntries) + this.getEntriesLength(deletedEntries);
    if (update) this.reloadByUseCase(useCase);
  }

  private reloadByUseCase(useCase: string) {
    switch (useCase) {
      case 'photoDocuments':      { if (this.router.url.includes('/time-tracking/pd-list'))          this.photoDocumentService.forceReload();    break; }
      case 'invoices':            { if (this.router.url.includes('/invoices/list'))                  this.invoicesService.forceReload();         break; }
      case 'EBS':                 { if (this.router.url.includes('/time-tracking/ebs-list'))         this.assignmentService.forceReload();       break; }
      case 'assignments':         { if (this.router.url.includes('/time-tracking/assignments-list')) this.assignmentService.forceReload();       break; }
      case 'mileageMoneyReports': { if (this.router.url.includes('/time-tracking/mm-list'))          this.mileageMoneyListService.forceReload(); break; }
      default:
        break;
    }
  }

  private getEntriesLength(object: any): number {
    return Object.keys(object).reduce((sum, key) => sum + object[key].length, 0);
  }

  private activityReportsNeedUpdate(newEntries: ActivityReportsCountersModel, deletedEntries: ActivityReportsCountersModel, skipNotification: boolean = null): void {
    this.reloadByUrl('/time-tracking/list',       '',              () => this.activityReportsService.forceReload(), !!(Object.values(newEntries).flat().length + Object.values(deletedEntries).flat().length) );
    this.reloadByUrl('/time-tracking/list',       'ar-approved',   () => this.activityReportsService.forceReload(), !!(newEntries.approved.length              + deletedEntries.approved.length)              );
    this.reloadByUrl('/time-tracking/list',       'ar-open',       () => this.activityReportsService.forceReload(), !!(newEntries.open.length                  + deletedEntries.open.length)                  );
    this.reloadByUrl('/time-tracking/list',       'ar-rejected',   () => this.activityReportsService.forceReload(), !!(newEntries.rejected.length              + deletedEntries.rejected.length)              );
    if (this.userService.isInternal) {
      this.reloadByUrl('/time-tracking/failed-erp', 'failed_export', () => this.failedErpService.forceReload(),     !!(newEntries.failed_export.length         + deletedEntries.failed_export.length), 'failed_export', 'ar' );
    }

    if (!skipNotification) this.needNotification(newEntries, 'activityReports');
  }

  private vacationRequestsNeedUpdate(newEntries: VacationRequestsCountersModel, deletedEntries: VacationRequestsCountersModel): void {
    this.reloadByUrl('/time-tracking/vr-list',    'vr-approved',   () => this.vacationRequestListService.forceReload(), !!(Object.values(newEntries).flat().length + Object.values(deletedEntries).flat().length)              );
    this.reloadByUrl('/time-tracking/vr-list',    'vr-awaiting',   () => this.vacationRequestListService.forceReload(), !!(Object.values(newEntries).flat().length + Object.values(deletedEntries).flat().length)              );

    this.reloadByUrl('/time-tracking/vr-list',    'vr-approved',   () => this.vacationRequestListService.forceReload(), !!(newEntries.approved.length      + deletedEntries.approved.length),      'vacations',     'approved' );
    this.reloadByUrl('/time-tracking/vr-list',    'vr-approved',   () => this.vacationRequestListService.forceReload(), !!(newEntries.open.length          + deletedEntries.open.length),          'vacations',     'open'     );
    this.reloadByUrl('/time-tracking/vr-list',    'vr-approved',   () => this.vacationRequestListService.forceReload(), !!(newEntries.rejected.length      + deletedEntries.rejected.length),      'vacations',     'rejected' );

    this.reloadByUrl('/time-tracking/vr-list',    'vr-awaiting',   () => this.vacationRequestListService.forceReload(), !!(newEntries.approved.length      + deletedEntries.approved.length),      'vacations',     'approved' );
    this.reloadByUrl('/time-tracking/vr-list',    'vr-awaiting',   () => this.vacationRequestListService.forceReload(), !!(newEntries.open.length          + deletedEntries.open.length),          'vacations',     'open'     );
    this.reloadByUrl('/time-tracking/vr-list',    'vr-awaiting',   () => this.vacationRequestListService.forceReload(), !!(newEntries.rejected.length      + deletedEntries.rejected.length),      'vacations',     'rejected' );

    if (this.userService.isInternal) {
      this.reloadByUrl('/time-tracking/failed-erp', 'failed_export', () => this.failedErpService.forceReload(),         !!(newEntries.failed_export.length + deletedEntries.failed_export.length), 'failed_export', 'vr'       );
    }
  }

  private reloadByUrl(url: string, filter: string, reloadCallback: Function, needReload: boolean = false, togglerField: string = null, togglerValue: string = null): void {
    let activeToggler, tab = this.sessionStorageService.activeTabValue;
    if (togglerField) activeToggler = this.sessionStorageService.headerTogglersValue[togglerField]?.find((tog: TogglerModel) => tog.active);

    if (tab && needReload && this.router.url.includes(url) && tab.counter === filter && (!activeToggler || activeToggler.value === togglerValue)) {
      this.notificationService.wait();
      this.sessionStorageService.reloadAvailable = true;
      reloadCallback.call(this);
    }
  }

  mapCounterMessage(message) {
    if (message.event === 'new_resource') this.reloadCounters(message);
    if (message.type  === 'working_period' && message.state) this.workingPeriodMessageHandler(message.state);
  }

  private reloadCounters(message): void {
    if (message.type === 'working_period') {
      runInInjectionContext(this.injector, () => { inject(ActivityReportsCountersService).reloadCounters(true); });
      this.showNewReportsDesktopNotification([{ id: message.id, creator: null }]);
    }
    if (message.type === 'photo_documents'          ) runInInjectionContext(this.injector, () => { inject(PhotoDocumentsCountersService).reloadCounters();               });
    if (message.type === 'vacation_request'         ) runInInjectionContext(this.injector, () => { inject(VacationRequestsCountersService).reloadCounters();             });
    if (message.type === 'assignments'              ) runInInjectionContext(this.injector, () => { inject(AssignmentsCountersService).reloadAssignmentsExpiredCounter(); });
    if (message.type === 'ebs'                      ) runInInjectionContext(this.injector, () => { inject(AssignmentsCountersService).reloadEBSCounter();                });
    if (message.type === 'invoices'                 ) runInInjectionContext(this.injector, () => { inject(InvoicesCountersService).reloadCounters();                     });
    if (message.type === 'standalone_mileage_report') runInInjectionContext(this.injector, () => { inject(MileageMoneyCountersService).reloadCounters();                 });
  }

  private workingPeriodMessageHandler(state): void {
    switch (state.from) {
      case 'released_by_customer':      { this.counterCountHandler('approvedReports',      -1, '/time-tracking/list', 'ar-approved', this.activityReportsService.forceReload); break; }
      case 'waiting_customer_approval': { this.counterCountHandler('openActivityReports',  -1, '/time-tracking/list', 'ar-awaiting', this.activityReportsService.forceReload); break; }
      case 'waiting_clarification':     { this.counterCountHandler('clarificationReports', -1, '/time-tracking/list', 'ar-rejected', this.activityReportsService.forceReload); break; }
    };

    switch (state.to) {
      case 'released_by_customer': { this.counterCountHandler('approvedReports',      1, '/time-tracking/list',  'ar-approved', this.activityReportsService.forceReload); break; }
      case 'rejected_by_customer': { this.counterCountHandler('clarificationReports', 1, '/time-tracking/list',  'ar-rejected', this.activityReportsService.forceReload); break; }
    }
  }

  private counterCountHandler(counterName: string, counterChange: number, url: string, filter: string, reloadCallback: Function) {
    let count = this.sidebarStorageService[`${counterName}CountValue`];
    this.sidebarStorageService[`change${this.capitalize(counterName)}Count`](count + counterChange);
    this.reloadByUrl(url, filter, reloadCallback);
  }

  private needNotification(newEntries: any, useCase: string): void {
    switch (useCase) {
      case 'activityReports':
        this.activityReportsNotification(newEntries);
        break;
    }
  }

  private activityReportsNotification(newEntries: ActivityReportsCountersModel): void {
    if (this.userService.isInternal && (newEntries?.approved?.length || newEntries?.rejected?.length)) {
      let entries = [...newEntries?.approved, ...newEntries?.rejected].filter(e => !e.creator);
      this.showNewReportsDesktopNotification(entries);
    } else if (this.userService.isCustomer && newEntries?.open?.length) {
      this.showNewReportsDesktopNotification(newEntries?.open.filter(e => !e.creator));
    }
  }

  private showNewReportsDesktopNotification(entries: ActivityReportsCountersTokenModel[]): void {
    if (!('Notification' in window)) return;
    if (Notification.permission !== 'granted') Notification.requestPermission();
    else {
      let notification = new Notification(this.userService.isCustomer ? 'myTempton Kundenverwaltung' : 'myTempton SB Verwaltung',{ 
        icon: 'assets/images/icon/favicon.ico',
        body: `Ein neuer Tätigkeitsnachweis steht zur Verfügung (${entries.length})`
      });

      notification.onclick = () => {
        window.focus();
        if (entries[0]?.id) {
          this.router.navigateByUrl(`/time-tracking/wp-details/${entries[0].id}`);
          this.sessionStorageService.changePageNumber(1);
          this.sessionStorageService.changeTotalPages(1);
          if (window?.history?.state?.data) window.history.state.data = null;

        }
      };
    }
  }

  private capitalize(string: string): string {
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
  }

}