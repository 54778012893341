import { ActivityReportMileageMoneyVersionModel, MileageMoneyVersionBasicModel, StandaloneMileageMoneyVersionModel } from "@shared/models";
import { ActivityReportMileageMoney, ExternalEmployee, StandaloneMileageMoney } from "@shared/factories";
import { FormatDatesService } from "@shared/services";

export class MileageMoneyVersionBasic {
  createdAt: Date;
  version:   number;
  event:     string;

  updatedBy: ExternalEmployee;
  constructor(data: MileageMoneyVersionBasicModel) {
    this.createdAt = FormatDatesService.parseDate(data.created_at);
    this.version   = data.version;
    this.event     = data.event;

    this.updatedBy = data.updated_by ? new ExternalEmployee(data.updated_by) : null;
  }

  toJSON(): MileageMoneyVersionBasicModel {
    return ({
      created_at: this.createdAt             ? this.createdAt.toISOString() : null,
      version:    this.version !== undefined ? this.version                 : null,
      event:      this.event   !== undefined ? this.event                   : null,

      updated_by: this.updatedBy             ? this.updatedBy.toJSON()      : null
    });
  }

}

export class StandaloneMileageMoneyVersion extends MileageMoneyVersionBasic {
  mileageMoneyReport: StandaloneMileageMoney;
  constructor(data: StandaloneMileageMoneyVersionModel) {
    super(data);

    let report       = data.report; 
    report.work_days = data.work_days;

    this.mileageMoneyReport = new StandaloneMileageMoney(data.report);
  }

  toJSON(): StandaloneMileageMoneyVersionModel {
    return Object.assign(super.toJSON(), {
      report:    this.mileageMoneyReport                   ? this.mileageMoneyReport.toJSON()                         : null,
      work_days: this.mileageMoneyReport?.workDays?.length ? this.mileageMoneyReport?.workDays.map(wd => wd.toJSON()) : null,
    });
  }

}

export class ActivityReportMileageMoneyVersion extends MileageMoneyVersionBasic {
  mileageMoneyReport: ActivityReportMileageMoney;
  updatedByUserGroup: string;
  constructor(data: ActivityReportMileageMoneyVersionModel) {
    super(data);

    this.mileageMoneyReport = new ActivityReportMileageMoney(data.mileage_money_report);
    this.updatedByUserGroup = data.updated_by_user_group;
  }

  toJSON(): ActivityReportMileageMoneyVersionModel {
    return Object.assign(super.toJSON(), {
      mileage_money_report:  this.mileageMoneyReport ? this.mileageMoneyReport.toJSON() : null,
      updated_by_user_group: this.updatedByUserGroup ? this.updatedByUserGroup          : null
    });
  }

}
