import { ActivityReportExtendedModel, ActivityReportMileageMoneyModel, LicensePlateModel, MileageMoneyReportBasicModel, MileageReportSimplifiedModel, StandaloneMileageMoneyPrefilledModel, StandaloneMileageMoneySubmitModel, StandaloneMileageMoneyModel, PrefilledWorkDayModel } from "@shared/models";
import { ActivityReportMileageMoneyDaily, Assignment, AssignmentBasicTitle, EmployeeBasic, ExternalEmployee, StandaloneMileageMoneyDaily, AssignmentWithExternalData } from "@shared/factories";
import { FormatDatesService } from "@shared/services";

export class MileageMoneyReportBasic {
  id:                  number;
  createdAt:           Date;

  calculatedSum:       number;
  customCalculatedSum: number;

  internalNote:        string;

  licensePlate:        LicensePlateModel;
  licensePlateMapped:  string;

  workDays:            (ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily)[];
  errors:               any = [];
  constructor(data: MileageMoneyReportBasicModel) {
    this.id                  = data.id                    ? data.id                                                              : null;
    this.createdAt           = FormatDatesService.parseDate(data.created_at);

    this.calculatedSum       = data.calculated_sum        ? parseFloat(data.calculated_sum)                                      : 0;
    this.customCalculatedSum = data.custom_calculated_sum ? parseFloat(data.custom_calculated_sum)                               : 0;

    this.internalNote        = data.internal_note         ? data.internal_note                                                   : null;

    this.licensePlate        = data.license_plate         ? data.license_plate                                                   : null;
    this.licensePlateMapped  = data.license_plate         ? `${data.license_plate?.country} ${this.licensePlate?.license_plate}` : '-';
  }

  get totalKm():  number { return this.workDays?.reduce((sum: number, val: ActivityReportMileageMoneyDaily) => { sum = sum + val.amountOfKm;    return sum }, 0) || 0; }
  get totalSum(): number { return this.workDays?.reduce((sum: number, val: ActivityReportMileageMoneyDaily) => { sum = sum + val.calculatedSum; return sum }, 0) || 0; }

  get activeWorkDays(): (ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily)[] {
    return this.workDays?.filter(m => !m.deleted) || [];
  }

  toJSON(): MileageMoneyReportBasicModel {
    return {
      id:                    this.id                  ? this.id                      : null,
      created_at:            this.createdAt           ? this.createdAt.toISOString() : null,

      calculated_sum:        this.calculatedSum       ? this.calculatedSum+''        : null,
      custom_calculated_sum: this.customCalculatedSum ? this.customCalculatedSum+''  : null,

      internal_note:         this.internalNote        ? this.internalNote            : null,
      license_plate:         this.licensePlate        ? this.licensePlate            : null
    };
  }

}

export class ActivityReportMileageMoney extends MileageMoneyReportBasic {
  workingPeriodId: number;
  updatedAt:       Date;

  workDays:        ActivityReportMileageMoneyDaily[];
  constructor(data: ActivityReportMileageMoneyModel) {
    super(data);

    this.updatedAt       = FormatDatesService.parseDate(data.updated_at);
    this.workingPeriodId = data.working_period_id    ? data.working_period_id                                             : null;

    this.workDays        = data.report_items?.length ? data.report_items.map(r => new ActivityReportMileageMoneyDaily(r)) : [];
  }

  toSubmitJSON() {
    return {
      custom_calculated_sum: FormatDatesService.notNull(this.customCalculatedSum) ? this.customCalculatedSum                 : null,
      report_items:          this.workDays?.length                                ? this.workDays.map(r => r.toSubmitJSON()) : [],
      internal_note:         this.internalNote                                    ? this.internalNote                        : null
    };
  }

  toJSON(): ActivityReportMileageMoneyModel {
    return Object.assign(super.toJSON(), {
      updated_at:        this.updatedAt        ? this.updatedAt.toISOString()       : null,
      working_period_id: this.workingPeriodId  ? this.workingPeriodId               : null,

      report_items:      this.workDays?.length ? this.workDays.map(r => r.toJSON()) : null
    });
  }

}

export class ActivityReportMileageMoneyExtended extends ActivityReportMileageMoney {
  startDate:        Date;
  endDate:          Date;
  calendarWeek:     string;

  externalEmployee: ExternalEmployee;
  assignment:       Assignment
  constructor(data: ActivityReportMileageMoneyModel, ar: ActivityReportExtendedModel) {
    super(data);

    this.startDate        = FormatDatesService.parseDate(ar.start_date);
    this.endDate          = FormatDatesService.parseDate(ar.end_date);
    this.calendarWeek     = FormatDatesService.calendarWeek(this.startDate);

    this.externalEmployee = ar.external_employee ? new ExternalEmployee(ar.external_employee) : null;
    this.assignment       = ar.assignment        ? new Assignment(ar.assignment)              : null;
  }

}

export class StandaloneMileageMoney extends MileageMoneyReportBasic {
  ebsDataId:                  number;
  licensePlateId:             number;
  splitChildId:               number;

  startDate:                  Date;
  endDate:                    Date;

  calendarWeek:               string;
  date:                       string;

  archivedAt:                 Date;

  state:                      string;
  workDays:                   StandaloneMileageMoneyDaily[];

  internalReview:             string;

  assignment:                 AssignmentBasicTitle;
  externalEmployee:           EmployeeBasic;

  belongsToInternalLocations: boolean;
  constructor(data: StandaloneMileageMoneyModel) {
    super(data);

    this.ebsDataId                  = data.ebs_data_id                   ? data.ebs_data_id                                              : null;
    this.licensePlateId             = data.license_plate_id              ? data.license_plate_id                                         : null;
    this.splitChildId               = data.split_child_id                ? data.split_child_id                                           : null;

    this.startDate                  = FormatDatesService.parseDate(data.start_date);
    this.endDate                    = FormatDatesService.parseDate(data.end_date);

    this.calendarWeek               = FormatDatesService.calendarWeek(this.startDate);
    this.date                       = FormatDatesService.period(this.startDate, this.endDate);

    this.archivedAt                 = FormatDatesService.parseDate(data.archived_at);

    this.state                      = data.state                         ? data.state                                                    : null;
    this.workDays                   = data.work_days?.length             ? data.work_days.map(wd => new StandaloneMileageMoneyDaily(wd)) : null;

    this.internalReview             = data.internal_review               ? data.internal_review                                          : null;

    this.assignment                 = data.assignment                    ? new AssignmentBasicTitle(data.assignment)                     : null;
    this.externalEmployee           = data.external_employee             ? new EmployeeBasic(data.external_employee)                     : null;

    this.belongsToInternalLocations = data.belongs_to_internal_locations ? data.belongs_to_internal_locations                            : null;
  }

  toJSON(): StandaloneMileageMoneyModel {
    return Object.assign(super.toJSON(), {
      ebs_data_id:                   this.ebsDataId                  ? this.ebsDataId                       : null,
      license_plate_id:              this.licensePlateId             ? this.licensePlateId                  : null,
      split_child_id:                this.splitChildId               ? this.splitChildId                    : null,

      start_date:                    this.startDate                  ? this.startDate.toISOString()         : null,
      end_date:                      this.endDate                    ? this.endDate.toISOString()           : null,

      archived_at:                   this.archivedAt                 ? this.archivedAt.toISOString()        : null,

      state:                         this.state                      ? this.state                           : null,
      work_days:                     this.workDays?.length           ? this.workDays.map(wd => wd.toJSON()) : null,

      internal_review:               this.internalReview             ? this.internalReview                  : null,

      assignment:                    this.assignment                 ? this.assignment                      : null,
      external_employee:             this.externalEmployee           ? this.externalEmployee.toJSON()       : null,

      belongs_to_internal_locations: this.belongsToInternalLocations ? this.belongsToInternalLocations      : null
    });
  }

  toSubmitJSON(): StandaloneMileageMoneySubmitModel {
    return {
      internal_note:         this.internalNote                                    ? this.internalNote                                           : null,
      custom_calculated_sum: FormatDatesService.notNull(this.customCalculatedSum) ? parseFloat((this.customCalculatedSum+'').replace(',', '.')) : null,
      work_days:             this.workDays?.length                                ? this.workDays.map(d => d.toSubmitJSON())                    : null
    };
  }

}

export class MileageReportSimplified {
  id:                         number;
  workingPeriodId:            number;
  createdAt:                  Date;
  archivedAt:                 Date;

  startDate:                  Date;
  endDate:                    Date;

  calendarWeek:               string;
  date:                       string;

  assignment:                 AssignmentWithExternalData;
  externalEmployee:           EmployeeBasic;

  totalKm:                    number;
  calculatedSum:              number;
  customCalculatedSum:        number;

  state:                      string;
  resourceType:               string;
  resourceTypeMapped:         string;

  belongsToInternalLocations: boolean;
  constructor(data: MileageReportSimplifiedModel) {
    this.id                         = data.id                            ? data.id                                         : null;
    this.workingPeriodId            = data.working_period_id             ? data.working_period_id                          : null;
    this.createdAt                  = FormatDatesService.parseDate(data.created_at);
    this.archivedAt                 = FormatDatesService.parseDate(data.archived_at);

    this.startDate                  = FormatDatesService.parseDate(data.start_date);
    this.endDate                    = FormatDatesService.parseDate(data.end_date);

    this.calendarWeek               = FormatDatesService.calendarWeek(this.startDate);
    this.date                       = FormatDatesService.period(this.startDate, this.endDate);

    this.assignment                 = data.assignment                    ? new AssignmentWithExternalData(data.assignment) : null;
    this.externalEmployee           = data.external_employee             ? this.combineExternalEmployeeData(data)          : null;

    this.totalKm                    = data.amount_of_km                  ? parseFloat(data.amount_of_km)                   : 0;
    this.calculatedSum              = data.calculated_sum                ? parseFloat(data.calculated_sum)                 : 0;
    this.customCalculatedSum        = data.custom_calculated_sum         ? parseFloat(data.custom_calculated_sum)          : 0;

    this.state                      = data.state                         ? data.state                                      : null;
    this.resourceType               = data.resource_type                 ? data.resource_type                              : null;
    this.resourceTypeMapped         = this.mapResourceType(data.resource_type);

    this.belongsToInternalLocations = data.belongs_to_internal_locations ? data.belongs_to_internal_locations              : null;
  }

  private combineExternalEmployeeData(data: MileageReportSimplifiedModel): ExternalEmployee {
    return new ExternalEmployee(Object.assign(data.external_employee, { personal_number: data.assignment.personal_number}));
  }

  private mapResourceType(type: string): string {
    switch (type) {
      case 'standalone_mileage_report':
        return 'Manuell';
      case 'working_period_mileage_report':
        return 'TN bezogen';
    };
  }

  toJSON(): MileageReportSimplifiedModel {
    return {
      id:                            this.id                         ? this.id                         : null,
      working_period_id:             this.workingPeriodId            ? this.workingPeriodId            : null,

      created_at:                    this.createdAt                  ? this.createdAt.toISOString()    : null,
      archived_at:                   this.archivedAt                 ? this.archivedAt.toISOString()   : null,

      start_date:                    this.startDate                  ? this.startDate.toISOString()    : null,
      end_date:                      this.endDate                    ? this.endDate.toISOString()      : null,

      amount_of_km:                  this.totalKm                    ? this.totalKm+''                 : null,
      calculated_sum:                this.calculatedSum              ? this.calculatedSum+''           : null,
      custom_calculated_sum:         this.customCalculatedSum        ? this.customCalculatedSum+''     : null,

      assignment:                    this.assignment                 ? this.assignment                 : null,
      external_employee:             this.externalEmployee           ? this.externalEmployee.toJSON()  : null,

      state:                         this.state                      ? this.state                      : null,
      resource_type:                 this.resourceType               ? this.resourceType               : null,

      belongs_to_internal_locations: this.belongsToInternalLocations ? this.belongsToInternalLocations : null
    };
  }

}

export class MileageMoneyPrefilledReport {
  assignmentId: number;
  ebsDataId:    number;
  workDays:     PrefilledWorkDay[];
  constructor(data: StandaloneMileageMoneyPrefilledModel) {
    this.assignmentId = data.assignment_id     ? data.assignment_id                                 : null;
    this.ebsDataId    = data.ebs_data_id       ? data.ebs_data_id                                   : null;
    this.workDays     = data.work_days?.length ? data.work_days.map(wd => new PrefilledWorkDay(wd)) : null;
  }

  toJSON(): StandaloneMileageMoneyPrefilledModel {
    return {
      assignment_id: this.assignmentId     ? this.assignmentId                    : null,
      ebs_data_id:   this.ebsDataId        ? this.ebsDataId                       : null,
      work_days:     this.workDays?.length ? this.workDays.map(wd => wd.toJSON()) : null
    };
  }

}

export class PrefilledWorkDay {
  date:                    Date;
  amountOfKm:              number;

  externalEmployeeAddress: string;
  assignmentAddress:       string;
  constructor(data: PrefilledWorkDayModel) {
    this.date                    = FormatDatesService.parseDate(data.date);
    this.amountOfKm              = data.amount_of_km              ? parseFloat(data.amount_of_km)  : 0;
    this.externalEmployeeAddress = data.external_employee_address ? data.external_employee_address : null;
    this.assignmentAddress       = data.assignment_address        ? data.assignment_address        : null;
  }

  toJSON(): PrefilledWorkDayModel {
    return {
      date:                      this.date                    ? this.date.toISOString()      : null,
      amount_of_km:              this.amountOfKm              ? this.amountOfKm+''           : null,
      external_employee_address: this.externalEmployeeAddress ? this.externalEmployeeAddress : null,
      assignment_address:        this.assignmentAddress       ? this.assignmentAddress       : null
    };
  }

}
