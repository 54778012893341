import { formatDate } from "@angular/common";
import {
  MileageMoneyDatesModel,
  ActivityReportMileageMoneyDailyModel,
  MileageMoneyBasicDailyModel,
  StandaloneMileageMoneyDailyModel,
  StandaloneMileageMoneyDailySubmitModel,
  MileageMoneySimplifyDailyModel
} from "@shared/models";
import { FormatDatesService } from "@shared/services";

export class MileageMoneyBasicDaily {
  id:                      number;
  mileageMoneyId:          number;

  assignmentAddress:       string;
  externalEmployeeAddress: string;
  
  amountOfKm:              number;
  amountPerKm:             number;

  totalSum:                number;
  calculatedSum:           number;

  errors:                  any = [];
  constructor(data: MileageMoneyBasicDailyModel) {
    this.id                      = data.id                        ? data.id                         : null;
    this.mileageMoneyId          = data.mileage_money_id          ? data.mileage_money_id           : null;

    this.amountOfKm              = data.amount_of_km              ? parseFloat(data.amount_of_km)   : null;
    this.amountPerKm             = data.amount_per_km             ? parseFloat(data.amount_per_km)  : null;

    this.assignmentAddress       = data.assignment_address        ? data.assignment_address         : null;
    this.externalEmployeeAddress = data.external_employee_address ? data.external_employee_address  : null;

    this.totalSum                 = data.total_sum                ? parseFloat(data.total_sum)      : null;
    this.calculatedSum            = data.calculated_sum           ? parseFloat(data.calculated_sum) : null;
  }

  toJSON(): MileageMoneyBasicDailyModel {
    return {
      id:                        this.id                      ? this.id                      : null,
      mileage_money_id:          this.mileageMoneyId          ? this.mileageMoneyId          : null,

      amount_of_km:              this.amountOfKm              ? this.amountOfKm+''           : null,
      amount_per_km:             this.amountPerKm             ? this.amountPerKm+''          : null,
      assignment_address:        this.assignmentAddress       ? this.assignmentAddress       : null,
      external_employee_address: this.externalEmployeeAddress ? this.externalEmployeeAddress : null,

      total_sum:                 this.totalSum                ? this.totalSum+''             : null,
      calculated_sum:            this.calculatedSum           ? this.calculatedSum+''        : null
    };
  }

}

export class ActivityReportMileageMoneyDaily extends MileageMoneyBasicDaily {
  mileageMoneyReportId: number;
  timeFrameId:          number;

  createdAt:            Date;
  updatedAt:            Date;

  startedAt:            Date;
  endedAt:              Date;

  totalAmountOfKm:      number;

  new:                  boolean;
  deleted:              boolean;
  constructor(data: ActivityReportMileageMoneyDailyModel, remove: boolean = null, create: boolean = null) {
    super(data);
    this.mileageMoneyReportId = data.mileage_money_report_id ? data.mileage_money_report_id : null;
    this.timeFrameId          = data.time_frame_id           ? data.time_frame_id           : null;

    this.createdAt            = FormatDatesService.parseDate(data.created_at);
    this.updatedAt            = FormatDatesService.parseDate(data.updated_at);

    this.startedAt            = FormatDatesService.parseDate(data.started_at);
    this.endedAt              = FormatDatesService.parseDate(data.ended_at);

    this.totalAmountOfKm      = this.amountOfKm * 2;

    this.new                  = create;
    this.deleted              = remove;
  }

  toSubmitJSON(): MileageMoneySimplifyDailyModel {
    return {
      id:                        this.id,
      amount_of_km:              this.totalAmountOfKm ? (this.totalAmountOfKm/2)+'' : null,
      amount_per_km:             this.amountPerKm+'',
      assignment_address:        this.assignmentAddress,
      external_employee_address: this.externalEmployeeAddress,
      total_sum:                 this.totalSum ? this.totalSum+'' : '0.0'
    };
  }

  toJSON(): ActivityReportMileageMoneyDailyModel {
    return Object.assign(super.toJSON(), {
      mileage_money_report_id: this.mileageMoneyReportId ? this.mileageMoneyReportId    : null,
      time_frame_id:           this.timeFrameId          ? this.timeFrameId             : null,

      created_at:              this.createdAt            ? this.createdAt.toISOString() : null,
      updated_at:              this.updatedAt            ? this.updatedAt.toISOString() : null,

      started_at:              this.startedAt            ? this.startedAt.toISOString() : null,
      ended_at:                this.endedAt              ? this.endedAt.toISOString()   : null
    });
  }

}

export class StandaloneMileageMoneyDaily extends MileageMoneyBasicDaily {
  date:    Date;

  new:     boolean;
  deleted: boolean;
  constructor(data: StandaloneMileageMoneyDailyModel, remove: boolean = null, create: boolean = null) {
    super(data);
    this.date    = FormatDatesService.parseDate(data.date);
    this.new     = create;
    this.deleted = remove;
  }

  toJSON(): StandaloneMileageMoneyDailyModel {
    return {
      id:                        this.id                      ? this.id                      : null,
      mileage_money_id:          this.mileageMoneyId          ? this.mileageMoneyId          : null,

      external_employee_address: this.externalEmployeeAddress ? this.externalEmployeeAddress : null,
      assignment_address:        this.assignmentAddress       ? this.assignmentAddress       : null,
      date:                      this.date                    ? this.date.toISOString()      : null,

      total_sum:                 this.totalSum                ? this.totalSum+''             : null,
      calculated_sum:            this.calculatedSum           ? this.calculatedSum+''        : null,

      amount_per_km:             this.amountPerKm             ? this.amountPerKm+''          : null,
      amount_of_km:              this.amountOfKm              ? this.amountOfKm+''           : null
    };
  }

  toSubmitJSON(): StandaloneMileageMoneyDailySubmitModel {
    return {
      id:                        this.id                      ? this.id                                  : null,
      mileage_money_id:          this.mileageMoneyId          ? this.mileageMoneyId                      : null,
      date:                      this.date                    ? formatDate(this.date, 'yyyy-MM-dd','de') : null,

      external_employee_address: this.externalEmployeeAddress ? this.externalEmployeeAddress             : null,
      assignment_address:        this.assignmentAddress       ? this.assignmentAddress                   : null,

      amount_of_km:              this.amountOfKm              ? this.amountOfKm+''                       : null,

      _destroy:                  this.deleted                 ? this.deleted                             : null
    };
  }

}

export class MileageMoneyDates {
  startsAt: Date;
  endsAt:   Date;
  constructor(data: MileageMoneyDatesModel) {
    this.startsAt = FormatDatesService.parseDate(data.starts_at);
    this.endsAt   = FormatDatesService.parseDate(data.ends_at);
  }

  toJSON(): MileageMoneyDatesModel {
    return ({
      starts_at: this.startsAt ? FormatDatesService.parseDateISO(this.startsAt) : null,
      ends_at:   this.endsAt   ? FormatDatesService.parseDateISO(this.endsAt)   : null
    });
  }

}
