import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';;
import { SidebarRouteExtended  } from '@shared/models';
import { SidebarStorageService } from '@shared/services';

@Component({
  selector:      'counters-cards',
  templateUrl: './counters-cards.component.html',
  host: { class: 'am-flex-wrap am-flex-mobile-nowrap am-flex-mobile-column gap-auto mt-auto' }
})
export class CountersCardsComponent implements OnInit {
  @Input() isCustomer:           boolean;
  @Input() isInternal:           boolean;
  @Input() activeTab:            SidebarRouteExtended;
  countersCards:                 SidebarRouteExtended[];

  approvedReportsCount:          number;
  openActivityReportsCount:      number;
  clarificationReportsCount:     number;
  failedERPCount:                number;
  expiringSoonAssignmentsCount:  number;
  activePhotoDocumentsCount:     number;
  unconfirmedEBSCount:           number;
  approvedVacationRequestsCount: number;
  awaitingVacationRequestsCount: number;
  rejectedVacationRequests:      number;
  failedVacationRequests:        number;
  awaitingMileageMoneyReports:   number;

  @Output() cardCallback = new EventEmitter<any>();
  constructor(private sidebarStorageService: SidebarStorageService) { }

  ngOnInit(): void {
    let navItemsMapped = this.sidebarStorageService.navItemsMapped.filter(i => !i.bottom);
    this.countersCards = this.collectCards(navItemsMapped);

    this.sidebarStorageService.approvedReportsCount.subscribe(         items => this.approvedReportsCount          = items);
    this.sidebarStorageService.openActivityReportsCount.subscribe(     items => this.openActivityReportsCount      = items);
    this.sidebarStorageService.clarificationReportsCount.subscribe(    items => this.clarificationReportsCount     = items);
    this.sidebarStorageService.failedERPCount.subscribe(               items => this.failedERPCount                = items);
    this.sidebarStorageService.activePhotoDocumentsCount.subscribe(    items => this.activePhotoDocumentsCount     = items);
    this.sidebarStorageService.unconfirmedEBSCount.subscribe(          items => this.unconfirmedEBSCount           = items);
    this.sidebarStorageService.expiringSoonAssignmentsCount.subscribe( items => this.expiringSoonAssignmentsCount  = items);
    this.sidebarStorageService.approvedVacationRequestsCount.subscribe(items => this.approvedVacationRequestsCount = items);
    this.sidebarStorageService.awaitingVacationRequestsCount.subscribe(items => this.awaitingVacationRequestsCount = items);
    this.sidebarStorageService.rejectedVacationRequests.subscribe(     items => this.rejectedVacationRequests      = items);
    this.sidebarStorageService.failedVacationRequests.subscribe(       items => this.failedVacationRequests        = items);
    this.sidebarStorageService.awaitingMileageMoneyReports.subscribe(  items => this.awaitingMileageMoneyReports   = items);
  }

  mapCardCount(counter: string): number | string {
    switch (counter) {
      case 'dashboard':
        return this.approvedReportsCount + this.openActivityReportsCount + this.clarificationReportsCount;
      case 'failed-erp':
        return this.failedERPCount || this.failedVacationRequests ? `${+this.failedERPCount}+${+this.failedVacationRequests}` : null;
      case 'vr-list':
        return this.approvedVacationRequestsCount+this.awaitingVacationRequestsCount+this.rejectedVacationRequests;
      case 'photo-doc':
        return this.activePhotoDocumentsCount;
      case 'ebs-list':
        return this.expiringSoonAssignmentsCount || this.unconfirmedEBSCount || 0;
      case 'mm-list':
        return this.awaitingMileageMoneyReports;
    };
  }

  changeDashboard(navItem: SidebarRouteExtended): void {
    this.cardCallback.emit(navItem);
  }

  private collectCards(navItems: SidebarRouteExtended[]): SidebarRouteExtended[] {
    return [
      { identifier: 'dashboard'                      },
      { identifier: 'failed-erp', internalOnly: true },
      { identifier: 'vr-list'                        },
      { identifier: 'photo-doc',  internalOnly: true },
      { identifier: 'ebs-list'                       },
      { identifier: 'mm-list',    internalOnly: true },
    ].filter(i => i.internalOnly ? this.isInternal : true)
     .map(i => navItems.find(n => n.identifier === i.identifier));
  }

}
