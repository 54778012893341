import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

import { SessionStorageService } from './session-storage.service';
import { UserService           } from './auth/user.service';
import { PillModel             } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class QueryCollectorService {
  constructor(
    private sessionStorageService: SessionStorageService,
    private userService:           UserService
  ) { }

  getTimeFramesQuery(filters: any): string {
    return '?'+[
      ...this.getPaginationQuery(),
      ...this.getSearchQuery(),
      ...this.getPillsQuery(),
      ...this.getTimeFramesTableQuery(filters),
      ...this.getWorkingPeriodTableQuery(filters),
      ...this.getSortingQuery('external_name',    0),
      ...this.getSortingQuery('assignment_title', 1),
      ...this.getSortingQuery('started_at',       2),
      filters.reporting ? ['reporting=1'] : []
    ].filter(q => q.length).join('&');
  }

  getHolidaysTimesQuery(from: Date = null, to: Date = null): string {
    let queryObj = {
      date_gteq: from ? formatDate(from, 'yyyy-MM-dd', 'de') : null,
      date_lteq: to   ? formatDate(to,   'yyyy-MM-dd', 'de') : null,
    };
    return `?${this.toQueryStringArray(queryObj, 'date').filter(q => q.length).join('&')}`;
  }

  getActivityReportsQuery(): string {
    return '?'+[
      ...this.getWorkingPeriodStatusFilter(),
      ...this.getPaginationQuery(),
      ...this.getSearchQuery(),
      ...this.getPillsQuery(),
      ...this.getSortingFlow(true)
    ].filter(q => q.length).join('&');
  }

  getFailedERPsQuery(betaFlag: string = null): string {
    let filters = this.sessionStorageService.headerFiltersValue.failed_erp;
    let dates   = this.sessionStorageService.failedERPDateFilters;
    
    return '?'+[
      ...this.getFailedERPFilter(Object.assign(filters, dates), true),
      ...this.getPaginationQuery(),
      ...this.getSearchQuery(),
      ...this.getPillsQuery(),
      ...this.getBetaQuery(betaFlag),
      ...this.getSortingFlow(true)
    ].filter(q => q.length).join('&');
  }

  getPhotoDocumentsQuery(): string {
    return '?'+[
      ...this.getPaginationQuery(),
      ...this.getSearchQuery(),
      ...this.getPillsQuery(),
      ...this.getSortingFlow(true)
    ].filter(q => q.length).join('&');
  }

  getCountersQuery(betaFlag: string = null): string {
    return '?'+[
      ...this.getPillsQuery(),
      ...this.getBetaQuery(betaFlag)
    ].filter(q => q.length).join('&');
  }

  getEBSCountersQuery(): string {
    return '?'+[
      ...this.getPillsQuery(),
      ...this.getActiveAssignmentsQuery()
    ].filter(q => q.length).join('&');
  }

  getActivityReportsArchiveQuery(): string {
    let filters = { ...this.sessionStorageService.activityReportsArchiveForm };

    return '?'+[
      ...this.getWorkingPeriodTableQuery(filters),
      ...this.getWorkingPeriodStatesQuery(filters, true),
      ...this.getExtraInfoQuery(filters),
      ...this.getFailedERPFilter(filters),
      ...this.getAssignmentsTableQuery(filters, 'assignment'),
      ...this.getExternalEmployeesTableQuery(filters),
      ...this.getTimeFramesTableQuery(filters),
      ...this.getPaginationQuery(),
      ...this.getSortingFlow(true),
      ...this.getPillsQuery()
    ].filter(q => q.length).join('&');
  }

  getPhotoDocumentsArchiveQuery(): string {
    let filters = {
      ...this.sessionStorageService.photoDocumentsArchiveForm
    };
    return '?'+[
      ...this.getPhotoDocumentsTableQuery(filters),
      ...this.getPhotoDocumentsZvooveQuery(filters),
      ...this.getExternalEmployeesTableQuery(filters),
      ...this.getPaginationQuery(),
      ...this.getSortingFlow(true),
      ...this.getPillsQuery()
    ].filter(q => q.length).join('&');
  }

  private getPhotoDocumentsTableQuery(filters): string[] {
    let queryObj = {
      archived_on_gteq:  filters.photoDocumentMovedToArchiveFrom ? formatDate(filters.photoDocumentMovedToArchiveFrom, 'yyyy-MM-dd', 'de') : null,
      archived_on_lteq:  filters.photoDocumentMovedToArchiveTo   ? formatDate(filters.photoDocumentMovedToArchiveTo,   'yyyy-MM-dd', 'de') : null,
      created_on_gteq:   filters.photoDocumentCreatedAtFrom      ? formatDate(filters.photoDocumentCreatedAtFrom,      'yyyy-MM-dd', 'de') : null,
      created_on_lteq:   filters.photoDocumentCreatedAtTo        ? formatDate(filters.photoDocumentCreatedAtTo,        'yyyy-MM-dd', 'de') : null,
      subject:           filters.photoDocumentSubject            ? this.parceSingleselect(filters.photoDocumentSubject)                    : null,
      subject_corrected: filters.photoDocumentCorrectedSubject   ? encodeURIComponent(filters.photoDocumentCorrectedSubject)               : null,
      assignment_ids:    filters.assignmentsIds                  ? this.parceMultiselect(filters.assignmentsIds)                           : null
    };
    return this.toQueryStringArray(queryObj, 'photo_documents');
  }

  private getPhotoDocumentsZvooveQuery(filters): string[] {
    let zvooveKontakt  = filters.photoDocumentReleasedToZvooveWithKontakt;
    let zvooveDokument = filters.photoDocumentReleasedToZvooveWithDokument;
    let query = '';

    if      (zvooveKontakt  && zvooveDokument)  query = '[released_to_zvoove]=true';
    else if (zvooveKontakt  && !zvooveDokument) query = '[released_to_zvoove_status]=submit_as_contact_zvoove';
    else if (!zvooveKontakt && zvooveDokument)  query = '[released_to_zvoove_status]=submit_as_doc_zvoove';

    return query ? [`q[photo_documents]${query}`] : [];
  }

  private getExternalEmployeesTableQuery(filters): string[] {
    let queryObj = {
      personal_number: filters.externalEmployeePersonalNumber ? filters.externalEmployeePersonalNumber              : null,
      ids:             filters.externalEmployeeName           ? this.parceMultiselect(filters.externalEmployeeName) : null,
    };
    return this.toQueryStringArray(queryObj, 'external_employees');
  }

  getExternalsForVacationRequestsQuery(filters: any = {}) {
    return '?'+[
      ...this.getSortingQuery('last_name', 0),
      ...this.getBetaQuery(filters),
      ...this.getPillsQuery(),
    ].filter(q => q.length).join('&');
  }

  getAssignmentArchiveQuery(): string {
    let filters: any = {
      ...this.sessionStorageService.ebsArchiveForm
    };
    return '?'+[
      ...this.getAssignmentsTableQuery(filters),
      ...this.getEbsTableQuery(filters),
      ...this.getExternalEmployeesTableQuery(filters),
      ...this.getCustomerCompaniesTableQuery(filters),
      ...this.getPaginationQuery(),
      ...this.getSearchQuery(),
      ...this.getPillsQuery(),
      ...this.getSortingFlow(true)
    ].filter(q => q.length).join('&');
  }

  getAssignmentListQuery(): string {
    return '?'+[
      ...this.getActiveAssignmentsQuery(),
      ...this.getAssignmentsTableQuery(this.sessionStorageService.headerFiltersValue.ebs),
      ...this.getPaginationQuery(),
      ...this.getSearchQuery(),
      ...this.getPillsQuery(),
      ...this.getSortingFlow(true)
    ].filter(q => q.length).join('&');
  }

  getAssignmentsByTimeRangeQuery(timeRange): string {
    return '?'+[
      ...this.getAssignmentsTableQuery(timeRange),
      ...this.getPillsQuery()
    ].filter(q => q.length).join('&');
  }

  getAssignmentsVacationRequestQuery(filters: any = {}): string {
    return '?'+[
      ...this.getAssignmentsTableQuery(filters),
      ...this.getEbsTableQuery(filters),
      ...this.getExternalEmployeesTableQuery(filters),
      ...this.getCustomerCompaniesTableQuery(filters),
      ...this.getPillsQuery(),
      ...this.getSortingQuery('title'),
      ...['per_page=50',`page=1`],
      ...this.getBetaQuery(filters),
    ].filter(q => q.length).join('&');
  }

  getAssignmentsPaginated(page: number, search: string = '', betaFlag: string = null): string {
    return '?'+[
      ...this.getPillsQuery(),
      ...this.getBetaQuery(betaFlag),
      ...this.getSortingQuery('title'),
      ...this.getSimplePaginationQuery(page),
      ...[`q[assignments][title]=${encodeURIComponent(search)}`]
    ].filter(q => q.length).join('&');
  }

  getAssignmentsQuery(filters) {
    return '?'+[
      ...this.getAssignmentsTableQuery(filters),
      ...this.getExternalEmployeesTableQuery(filters),
      ...this.getPillsQuery(),
      ...this.getBetaQuery(filters),
      ...this.getSortingQuery('title')
    ].filter(q => q.length).join('&');
  }

  getCustomerCompaniesPaginated(page: number, search: string = ''): string {
    return '?'+[
      ...this.getPillsQuery(),
      ...this.getSortingQuery('name'),
      ...this.getSimplePaginationQuery(page),
      ...[`q[customers_companies][name]=${encodeURIComponent(search)}`]
    ].filter(q => q.length).join('&');
  }

  getExternalsPaginated(page: number, search: string = '', filters: any = {}): string {
    return '?'+[
      ...this.getPillsQuery(),
      ...this.getBetaQuery(filters),
      ...this.getAssignmentsTableQuery(filters, 'assignment'),
      ...this.getCustomersTableQuery(filters),
      ...this.getSortingQuery('full_name'),
      ...this.getSimplePaginationQuery(page),
      ...[`q[external_employees][name]=${encodeURIComponent(search)}`]
    ].filter(q => q.length).join('&');
  }

  getDocumentTypesPaginated(page: number, search: string = ''): string {
    return '?'+[
      ...this.getPillsQuery(),
      ...this.getSortingQuery('name'),
      ...this.getSimplePaginationQuery(page),
      ...[`q[document_types][name]=${encodeURIComponent(search)}`]
    ].filter(q => q.length).join('&');
  }

  private getWorkingPeriodTableQuery(filters): string[] {
    let queryObj = {
      end_date_gteq:   filters.workingPeriodDateFrom      ? formatDate(filters.workingPeriodDateFrom,      'yyyy-MM-dd', 'de') : null,
      start_date_lteq: filters.workingPeriodDateTo        ? formatDate(filters.workingPeriodDateTo,        'yyyy-MM-dd', 'de') : null,
      created_on_gteq: filters.workingPeriodCreatedAtFrom ? formatDate(filters.workingPeriodCreatedAtFrom, 'yyyy-MM-dd', 'de') : null,
      created_on_lteq: filters.workingPeriodCreatedAtTo   ? formatDate(filters.workingPeriodCreatedAtTo,   'yyyy-MM-dd', 'de') : null,
      assignment_ids:  filters.assignmentsIds             ? this.parceMultiselect(filters.assignmentsIds)                      : null,
      approved:        filters.timeFramesApproved         ? filters.timeFramesApproved                                         : null,
      opened:          filters.timeFramesOpened           ? filters.timeFramesOpened                                           : null,
    };
    return this.toQueryStringArray(queryObj, 'working_periods');
  }

  private getWorkingPeriodStatesQuery(filters, archive: boolean = null): string[] {
    let role      = this.userService.isInternal ? 'internal' : 'customer';
    let dashboard = archive                     ? 'archive'  : 'dashboard';

    if ((this.userService.isCustomer || !archive) && !(filters['workingPeriodApproved'] || filters['workingPeriodOpened'] || filters['workingPeriodRejected'])) {
      filters['workingPeriodApproved'] = true;
      filters['workingPeriodOpened']   = true;
      filters['workingPeriodRejected'] = true;
    }

    if (this.userService.isInternal && archive && !(filters['workingPeriodApproved'] || filters['workingPeriodArchived'])) {
      filters['workingPeriodApproved'] = true;
      filters['workingPeriodRejected'] = true;
      filters['workingPeriodArchived'] = true;
    }

    if (this.userService.isInternal && archive && filters['workingPeriodArchived'] && !filters['workingPeriodApproved']) {
      filters['workingPeriodRejected'] = true;
    }

    let queryObj = { 
      approved:      filters.workingPeriodApproved ? filters.workingPeriodApproved : null,
      auto_archived: filters.workingPeriodArchived ? filters.workingPeriodArchived : null,
      open:          filters.workingPeriodOpened   ? filters.workingPeriodOpened   : null,
      rejected:      filters.workingPeriodRejected ? filters.workingPeriodRejected : null,
    };

    let states = [];
    for (const key in queryObj) {
      if (queryObj[key]) states.push(key);
    }
  
    return [`q[${role}_${dashboard}][states]=${states.join(',')}`];
  }

  private getExtraInfoQuery(filters): string[] {
    let queryObj = {
      checked_internally: filters.extraInfoCheckedInternally ? filters.extraInfoCheckedInternally : null,
      with_attachment:    filters.extraInfoWithAttachment    ? filters.extraInfoWithAttachment    : null,
      with_mileage_money: filters.extraInfoWithMileageMoney  ? filters.extraInfoWithMileageMoney  : null,
      with_holidays:      filters.extraInfoWithHolidays      ? filters.extraInfoWithHolidays      : null
    };
    return this.toQueryStringArray(queryObj, 'additional_info');
  }

  private getAssignmentsTableQuery(filters: any, extendTable: string = ''): string[] {
    extendTable = extendTable ? extendTable+'_' : '';

    let queryObj = {
      title:                          !!filters.assignmentTitle                     ? encodeURIComponent(filters.assignmentTitle)                        : null,
      [`${extendTable}ends_on_gteq`]:   filters.assignmentDateFrom                  ? formatDate(filters.assignmentDateFrom,         'yyyy-MM-dd', 'de') : null,
      [`${extendTable}starts_on_lteq`]: filters.assignmentDateTo                    ? formatDate(filters.assignmentDateTo,           'yyyy-MM-dd', 'de') : null,
      ebs_created_on_gteq:              filters.assignmentEBSCreatedAtFrom          ? formatDate(filters.assignmentEBSCreatedAtFrom, 'yyyy-MM-dd', 'de') : null,
      ebs_created_on_lteq:              filters.assignmentEBSCreatedAtTo            ? formatDate(filters.assignmentEBSCreatedAtTo,   'yyyy-MM-dd', 'de') : null,
      active:                           this.valueNotNull(filters.assignmentActive) ? filters.assignmentActive                                           : null,
      assignment_ids:                   filters.assignmentsIds                      ? this.parceMultiselect(filters.assignmentsIds)                      : null,
      ebs_confirmed:                    this.userService.isCustomer || filters.ebsConfirmed && filters.ebsUnConfirmed ? null : filters.ebsConfirmed ? true : filters.ebsUnConfirmed ? false : null
    };
    return this.toQueryStringArray(queryObj, 'assignments');
  }

  private getEbsTableQuery(filters): string[] {
    let queryObj = {
      archived: filters.ebsArchived && filters.ebsUnArchived ? null : filters.ebsArchived ? true : filters.ebsUnArchived ? false : null
    };
    return this.toQueryStringArray(queryObj, 'ebs_data');
  }

  getCustomerCompanies(): string {
    return '?'+[
      ...this.getPaginationQuery(),
      ...this.getSearchQuery(),
      ...this.getPillsQuery(),
      ...this.getSortingFlow(true)
    ].filter(q => q.length).join('&');
  }

  private getCustomersTableQuery(filters): string[] {
    let queryObj = {
      activated: filters.customersActivated ? filters.customersActivated : null,
    };
    return this.toQueryStringArray(queryObj, 'customers');
  }

  private getCustomerCompaniesTableQuery(filters): string[] {
    let queryObj = {
      name: this.valueNotNull(filters.customerCompanyName) ? this.parceSingleselect(filters.customerCompanyName) : null
    };
    return this.toQueryStringArray(queryObj, 'customers_companies');
  }

  private getTimeFramesTableQuery(filters): string[] {
    let queryObj = {
      started_on_gteq: filters.timeFrameDateFrom           ? formatDate(filters.timeFrameDateFrom, 'yyyy-MM-dd', 'de') : null,
      ended_on_lteq:   filters.timeFrameDateTo             ? formatDate(filters.timeFrameDateTo,   'yyyy-MM-dd', 'de') : null,
      time_from:       filters.timeFrameTimeFrom           ? filters.timeFrameTimeFrom + ':00'                         : null,
      time_to:         filters.timeFrameTimeTo             ? filters.timeFrameTimeTo   + ':00'                         : null,
      corrected_dates: filters.timeFramesWithCorrectedTime ? filters.timeFramesWithCorrectedTime                       : null
    };
    return this.toQueryStringArray(queryObj, 'time_frames');
  }

  private toQueryStringArray(queryObj, queryTable: string): string[] {
    let queryL = [];
    for (const key in queryObj) if (queryObj[key] !== null) queryL.push(`q[${queryTable}][${key}]=${queryObj[key]}`);
    return queryL;
  }

  private getFailedERPFilter(filters, erp: boolean = null): string[] {
    let togglers = erp ? 'failed_erp' : 'archive';
    let useCase  = this.sessionStorageService.headerTogglersValue[togglers].find(toggler => toggler.active).value;
    let table    = useCase === 'ar' ? 'working_periods' : 'vacation_requests';
    let paramD   = useCase === 'ar' ? 'archived'        : 'internal_reviewed';
    let query    = [];

    let loadAllERP = erp && (!filters.zvooveImportFailed && !filters.zvooveImportResolvedManually);
    if (filters.zvooveImportSuccessfull)                    query.push(`q[${table}][export_state][]=successful_export` );
    if (filters.zvooveImportFailed           || loadAllERP) query.push(`q[${table}][export_state][]=failed_export`     );
    if (filters.zvooveImportResolvedManually || loadAllERP) query.push(`q[${table}][export_state][]=resolved_manually` );

    if (filters.approvedAtOrDeletedAtFrom) query.push(`q[${table}][${paramD}_on_gteq]=${formatDate(filters.approvedAtOrDeletedAtFrom, 'yyyy-MM-dd', 'de')}` );
    if (filters.approvedAtOrDeletedAtTo)   query.push(`q[${table}][${paramD}_on_lteq]=${formatDate(filters.approvedAtOrDeletedAtTo,   'yyyy-MM-dd', 'de')}` );

    return query;
  }

  private getWorkingPeriodStatusFilter(): string[] {
    let statusfilter = this.sessionStorageService.activeTabValue.filter;
    let filters = {};
    switch (+statusfilter) {
      case 1:
        filters['workingPeriodApproved'] = true;
        break;
      case 2:
        filters['workingPeriodOpened']   = true;
        break;
      case 3:
        filters['workingPeriodRejected'] = true;
        break;
      default:
        filters['workingPeriodApproved'] = true;
        filters['workingPeriodOpened']   = true;
        filters['workingPeriodRejected'] = true;
        break;
    }

    let query = this.getWorkingPeriodStatesQuery(filters);
    if (this.userService.isCustomer) query.push(...this.getActiveAssignmentsQuery());
    return query;
  }

  private getActiveAssignmentsQuery(): string[] {
    return ['q[assignments][active]=true', 'q[ebs_data][archived]=false'];
  }

  private getPaginationQuery(): string[] {
    return [
      `per_page=${this.sessionStorageService.itemsPerPageValue}`,
      `page=${this.sessionStorageService.pageNumberValue}`
    ];
  }

  private getSimplePaginationQuery(page: number): string[] {
    return [
      'per_page=10',
      `page=${page}`
    ];
  }

  private getSearchQuery(): string[] {
    let search = this.sessionStorageService.searchValueValue;
    if (search) return [`q[search_term]=${encodeURIComponent(search)}`];
    else return [];
  }

  private getSortingFlow(relavancy: boolean = null): string[] {
    let search      = this.sessionStorageService.searchValueValue;
    let sortingFLow = this.sessionStorageService.sortingFlowValue;
    if (!sortingFLow || !sortingFLow.length) return [];

    let sortColumns = sortingFLow[sortingFLow.length - 1].columns;
    let sortOrder   = sortingFLow[sortingFLow.length - 1].order;

    if (relavancy) {
      let relavancySort = sortColumns.indexOf('search_term_relevancy');
      if (search && relavancySort < 0) {
        sortColumns.unshift('search_term_relevancy');
        sortOrder.unshift(false);
      }
      if (!search && relavancySort >= 0) {
        sortColumns.splice(relavancySort, 1);
        sortOrder.splice(relavancySort, 1);
      }
    }

    return sortColumns.map((s, index) => this.getSortingQuery(s, index, !!sortOrder[index])[0]);
  }

  private getSortingDirection(order: boolean): string {
    return order ? 'asc' : 'desc';
  }

  private getSortingQuery(name: string, index: number = 0, dir: boolean = true): string[] {
    return [`q[sort][${index}][pos]=${index}&q[sort][${index}][name]=${name}&q[sort][${index}][dir]=${this.getSortingDirection(dir)}`];
  }

  private getBetaQuery(filters: any = {}): string[] {
    let tableName = this.userService.isInternal ? 'beta_locations' : 'beta_customer_companies';
    let queryObj = {
      feature: filters?.betaFeature ? filters.betaFeature : null,
    };
    return this.toQueryStringArray(queryObj, tableName);
  }

  private getPillsQuery(): string[] {
    let pills       = this.sessionStorageService.pillsValue;
    let activePills = this.sessionStorageService.activePills;

    if (this.userService.isCustomer)                                        return activePills.map(c => c.company_number).map(c => `q[customers_companies][company_numbers][]=${c}`);
    if (this.userService.isInternal && pills.length !== activePills.length) return activePills.map(c => c.location).map(l => `q[locations][]=${l}`);
    else return [];
  }
 
  private getCompaniesInvoiceQuery(): string[] {
    return this.sessionStorageService.activePills.map(c => c.company_number).map(c => `q[customer_invoices][company_numbers]=${c}`);
  }

  getInvoicesQuery(): string {
    return '?'+[
      ...this.getPaginationQuery(),
      ...this.getSearchQuery(),
      ...this.getCompaniesInvoiceQuery(),
      ...this.getSortingFlow(true)
    ].filter(q => q.length).join('&');
  }

  getInvoicesArchiveQuery() {
    return '?'+[
      ...this.getPaginationQuery(),
      ...this.getSearchQuery(),
      ...this.getCompaniesInvoiceQuery(),
      ...this.getSortingFlow(true)
    ].filter(q => q.length).join('&');
  }

  getReleasesQuery() {
    return '?'+[
      ...this.getPaginationQuery(),
      ...this.getSearchQuery(),
      ...this.getSortingFlow()
    ].filter(q => q.length).join('&');
  }

  getVacationRequestsQuery(): string {
    return '?'+[
      ...this.getTogglerStateFilter('vacations'),
      ...this.getPaginationQuery(),
      ...this.getSearchQuery(),
      ...this.getPillsQuery(),
      ...this.getSortingFlow(true)
    ].filter(q => q.length).join('&');
  }

  private getVacationRequestTableQuery(filters): string[] {
    let queryObj = {
      created_on_gteq:    filters.vacationRequestCreatedAtFrom ? formatDate(filters.vacationRequestCreatedAtFrom, 'yyyy-MM-dd', 'de')  : null,
      created_on_lteq:    filters.vacationRequestCreatedAtTo   ? formatDate(filters.vacationRequestCreatedAtTo,   'yyyy-MM-dd', 'de')  : null,
      starts_on_gteq:     filters.vacationRequestStartsOn      ? formatDate(filters.vacationRequestStartsOn,      'yyyy-MM-dd', 'de')  : null,
      ends_on_lteq:       filters.vacationRequestEndsOn        ? formatDate(filters.vacationRequestEndsOn,        'yyyy-MM-dd', 'de')  : null,
      leave_type:         filters.vacationRequestLeaveType     ? this.parceSingleselect(filters.vacationRequestLeaveType)              : null,
      reason:             filters.vacationRequestReason        ? this.parceSingleselect(filters.vacationRequestReason)                 : null,
      creation_initiator: filters.vacationRequestInitiator     ? this.parceSingleselect(filters.vacationRequestInitiator)              : null,
    };
    return this.toQueryStringArray(queryObj, 'vacation_requests');
  }

  private getVacationRequestArchiveTableQuery(filters): string[] {
    let queryObj = {
      assignment_ids:         filters.assignmentsIds             ? this.parceMultiselect(filters.assignmentsIds) : null,
      prechecked_by_internal: filters.extraInfoCheckedInternally ? filters.extraInfoCheckedInternally            : null,
      with_holidays:          filters.extraInfoWithHolidays      ? filters.extraInfoWithHolidays                 : null,
      creator:                filters.extraInfoCreatedByInternal ? 'Internal Employee'                           : null
    };
    return this.toQueryStringArray(queryObj, 'vacation_requests');
  }

  private getCheckboxStateFilter(filters, useCase: string): string[] {
    let board, query = [];
    if (this.userService.isCustomer) board = 'customer_archive';
    if (this.userService.isInternal) board = 'internal_archive';

    let { approved, rejected } = this.getCheckboxFieldNames(useCase);

    if (filters[approved] && !filters[rejected]) query.push(`q[${board}][states]=approved`);
    if (filters[rejected] && !filters[approved]) query.push(`q[${board}][states]=rejected`);
    return query;
  }

  private getCheckboxFieldNames(useCase: string): { approved: string, rejected: string } {
    switch (useCase) {
      case 'vacation_requests':
        return { approved: 'vacationRequestApprovedByInternal', rejected: 'vacationRequestRejectedByInternal' };
      case 'mileage_money':
        return { approved: 'mileageMoneyApproved', rejected: 'mileageMoneyRejected' };
      default:
        break;
    }
  }

  getVacationRequestsArchiveQuery() {
    let filters: any = { ...this.sessionStorageService.vacationRequestsArchiveForm };
    filters.betaFeature = 'vacation_requests';

    return '?'+[
      ...this.getVacationRequestTableQuery(filters),
      ...this.getVacationRequestArchiveTableQuery(filters),
      ...this.getCheckboxStateFilter(filters, 'vacation_requests'),
      ...this.getFailedERPFilter(filters),
      ...this.getAssignmentsTableQuery(filters, 'assignment'),
      ...this.getExternalEmployeesTableQuery(filters),
      ...this.getPaginationQuery(),
      ...this.getSortingFlow(true),
      ...this.getPillsQuery()
    ].filter(q => q.length).join('&');
  }

  getMileageReportsQuery(): string {
    return '?'+[
      ...this.getPaginationQuery(),
      ...this.getSearchQuery(),
      ...this.getPillsQuery(),
      ...this.getSortingFlow(true),
      ...['q[internal_dashboard][states]=open']
    ].filter(q => q.length).join('&');
  }

  getMileageReportsArchiveQuery() {
    let filters: any = { ...this.sessionStorageService.mileageMoneyArchiveForm };
    filters.betaFeature = 'standalone_mileage_reports';

    return '?'+[
      ...this.getMileageMoneyTableQuery(filters),
      ...this.getCheckboxStateFilter(filters, 'mileage_money'),
      ...this.getExternalEmployeesTableQuery(filters),
      ...this.getPaginationQuery(),
      ...this.getSortingFlow(true),
      ...this.getPillsQuery(),
      ...this.getBetaQuery(filters)
    ].filter(q => q.length).join('&');
  }

  private getMileageMoneyTableQuery(filters): string[] {
    let queryObj = {
      created_on_gteq: filters.mileageMoneyCreatedAtFrom ? formatDate(filters.mileageMoneyCreatedAtFrom, 'yyyy-MM-dd', 'de') : null,
      created_on_lteq: filters.mileageMoneyCreatedAtTo   ? formatDate(filters.mileageMoneyCreatedAtTo,   'yyyy-MM-dd', 'de') : null,
      end_date_gteq:   filters.mileageMoneyStartsOn      ? formatDate(filters.mileageMoneyStartsOn,      'yyyy-MM-dd', 'de') : null,
      start_date_lteq: filters.mileageMoneyEndsOn        ? formatDate(filters.mileageMoneyEndsOn,        'yyyy-MM-dd', 'de') : null,
      assignment_ids:  filters.assignmentsIds            ? this.parceMultiselect(filters.assignmentsIds)                     : null,
      resource_type:   this.collectCheckboxes(filters.standaloneMileageReportType,    'standalone_mileage_report',
                                              filters.workingPeriodMileageReportType, 'working_period_mileage_report')
    };
    return this.toQueryStringArray(queryObj, 'mileage_reports');
  }

  private collectCheckboxes(a: boolean, aValue: string, b: boolean, bValue: string): string {
    if ( a && !b) return aValue;
    if (!a &&  b) return bValue;
    if (!a && !b) return null;
  }

  private getTogglerStateFilter(toggler: string): string[] {
    let board;
    if (this.userService.isCustomer) board = 'customer_dashboard';
    if (this.userService.isInternal) board = 'internal_dashboard';

    let togglers = this.sessionStorageService.headerTogglersValue[toggler] || null;
    let state    = togglers.find(tog => tog.active);
    return state ? [`q[${board}][states]=${state.value}`] : [];
  }

  private valueNotNull(value: any): boolean {
    return value !== undefined && value !== null;
  }

  private parceSingleselect(value: any): string {
    return encodeURIComponent(value.value);
  }

  private parceMultiselect(value: any): string {
    return encodeURIComponent(value.split(';').map(e => e.split('_')[0]).join(','));
  }

}
