import { SidebarRouteExtended } from '@shared/models';

export const TimeTrackingSidebarItems: SidebarRouteExtended[] = [
  { identifier: 'dashboard',          path: 'list',                  icon: 'icon-tempton-portals',                             title: 'Zeiterfassung',                                  dashboardTitle: 'Zeiterfassung Übersicht', sortByDefault: ['portal_review_first', 'created_at'], dashboardCardsEnabled: true, children: [
  { identifier: 'ar-approved',        path: 'list', filter: '1',     icon: 'icon-circle-tick',       counter: 'approved',      title: 'TN Kundenfreigabe vorhanden',  isInternal: true, dashboardTitle: 'Tätigkeitsnachweise Kundenfreigabe vorhanden' },
  { identifier: 'ar-rejected',        path: 'list', filter: '3',     icon: 'icon-circle-cross',      counter: 'rejected',      title: 'TN Klärungsbedarf',            isInternal: true, dashboardTitle: 'Tätigkeitsnachweise Klärungsbedarf'           },
  { identifier: 'ar-open',            path: 'list', filter: '2',     icon: 'icon-circle-time',       counter: 'open',          title: 'Warten auf TN Kundenfreigabe', isInternal: true, dashboardTitle: 'Warten auf TN Kundenfreigabe'                 },
  { identifier: 'failed-erp',         path: 'failed-erp',            icon: 'icon-warning',           counter: 'failed_export', title: 'Fehlerhafte Übertragungen',    isInternal: true, dashboardTitle: 'Fehlerhafte Übertragungen', extra: true       },
  { identifier: 'ar-new',             path: 'new-activity-report',   icon: 'icon-time-tracking-add',                           title: 'TN erstellen',                 isInternal: true, dashboardTitle: 'TN erstellen'                                 },

  { identifier: 'ar-open',            path: 'list', filter: '2',     icon: 'icon-circle-time',       counter: 'open',          title: 'Offene TN',                    isCustomer: true, dashboardTitle: 'Offene Tätigkeitsnachweise',                  },
  { identifier: 'ar-approved',        path: 'list', filter: '1',     icon: 'icon-circle-tick',       counter: 'approved',      title: 'Freigegebene TN',              isCustomer: true, dashboardTitle: 'Freigegebene Tätigkeitsnachweise',            },
  { identifier: 'ar-rejected',        path: 'list', filter: '3',     icon: 'icon-circle-cross',      counter: 'rejected',      title: 'Abgelehnte TN',                isCustomer: true, dashboardTitle: 'Abgelehnte Tätigkeitsnachweise',              },
  ]},
  { identifier: 'vr-list',            path: 'vr-list',               icon: 'icon-holiday',           counter: 'vr-awaiting',   title: 'Urlaubsanträge',               isCustomer: true, dashboardTitle: 'Urlaubsanträge',          sortByDefault: ['customer_state_priority', 'created_at'], betaFlag: 'vacation_requests'          },
  { identifier: 'vr-list',            path: 'vr-list',               icon: 'icon-holiday',           counter: 'vr-approved',   title: 'Urlaub',                       isInternal: true, dashboardTitle: 'Urlaubsanträge',          sortByDefault: ['internal_state_priority', 'created_at'], betaFlag: 'vacation_requests'          },
  { identifier: 'vr-new',             path: 'vr-new',                icon: 'icon-holiday-add',                                 title: 'Urlaubsantrag erstellen',      isInternal: true, dashboardTitle: 'Urlaubsantrag erstellen',                                                           betaFlag: 'vacation_requests'          },
  { identifier: 'companies-list',     path: 'companies-list',        icon: 'icon-users',                                       title: 'Kunden',                       isInternal: true, dashboardTitle: 'Kunden',                  sortOrderByDefault: [true]                                                                       },
  { identifier: 'photo-doc',          path: 'pd-list',               icon: 'icon-picture',           counter: 'photo',         title: 'Fotodokument',                 isInternal: true, dashboardTitle: "Fotodokument",            sortByDefault: ['read_at', 'created_at'],                 sortOrderByDefault: [true]             },
  { identifier: 'ebs-list',           path: 'ebs-list',              icon: 'icon-user',              counter: 'ebs',           title: 'Mitarbeiter',                  isInternal: true, dashboardTitle: 'Einsatzbegleitscheine',   sortByDefault: ['ebs_id']                                                                        },
  { identifier: 'mm-list',            path: 'mm-list',               icon: 'icon-car',               counter: 'mm-open',       title: 'Kilometergeldberichte',        isInternal: true, dashboardTitle: 'Kilometergeldberichte',   sortOrderByDefault: [true],                               betaFlag: 'standalone_mileage_reports' },

  { identifier: 'ebs-list',           path: 'assignments-list',      icon: 'icon-user',              counter: 'ebs',           title: 'Mitarbeiter',                  isCustomer: true, dashboardTitle: 'Mitarbeiter',             sortByDefault: ['ends_at'],                               sortOrderByDefault: [true]             },
  { identifier: 'assignments-export', path: 'assignments-export',    icon: 'icon-excel',                                       title: 'Reporting',                    isCustomer: true, dashboardTitle: 'Reporting',                                                                                                                },

  { identifier: 'archive',            path: 'search-archiv',         icon: 'icon-archive',                                     title: 'Archiv',                                         dashboardTitle: 'Archiv', children: [
  { identifier: 'archive-ar',         path: 'search-archiv?tab=ar',  icon: 'icon-time-tracking',                               title: 'Tätigkeitsnachweis',                             dashboardTitle: 'Archiv'                                         },
  { identifier: 'archive-pd',         path: 'search-archiv?tab=pd',  icon: 'icon-picture',                                     title: 'Fotodokument',                 isInternal: true, dashboardTitle: 'Archiv',                                        },
  { identifier: 'archive-ebs',        path: 'search-archiv?tab=ebs', icon: 'icon-user',                                        title: 'Einsatzbegleitscheine',        isInternal: true, dashboardTitle: 'Archiv',                                        },
  { identifier: 'archive-vr',         path: 'search-archiv?tab=vr',  icon: 'icon-holiday',                                     title: 'Urlaubsanträge',                                 dashboardTitle: 'Archiv', betaFlag: 'vacation_requests'          },
  { identifier: 'archive-mm',         path: 'search-archiv?tab=mm',  icon: 'icon-car',                                         title: 'Kilometergeldberichte',        isInternal: true, dashboardTitle: 'Archiv', betaFlag: 'standalone_mileage_reports' }
  ]},

  { identifier: 'logout',                                            icon: 'icon-exit',            bottom: true,               title: 'Logout'                                                                                                                  },
  { identifier: 'hilfe',                                             icon: 'icon-help',            bottom: true,               title: 'Hilfe',                        isCustomer: true, externalPath: 'https://www.tempton.de/download/myTEMPTON_Zeiterfassung' },
  { identifier: 'hilfe',                                             icon: 'icon-help',            bottom: true,               title: 'Erste Hilfe & Anleitung',      isInternal: true, externalPath: 'https://akademie.tempton.net/sachbearbeiter-verwaltung'  },
  { identifier: 'releases',           path: 'releases',              icon: 'icon-releases',        bottom: true,               title: 'Releases',                                       dashboardTitle: 'Releases', sortByDefault: ['released_at']              },
];
