import { Injectable } from "@angular/core";
import { formatDate } from "@angular/common";
import { PdfMakeWrapper, Txt, Img, Table, Columns, Ul } from "pdfmake-wrapper";

import { PhotoDocument, ActivityReportExtended, EBS, Assignment, ContractExtension, Weeks, AssignmentExtended, ActivityReportSimplified, VacationRequest, VacationRequestExtended, StandaloneMileageMoney, ActivityReportMileageMoneyExtended, ActivityReportMileageMoneyDaily, StandaloneMileageMoneyDaily, MileageReportSimplified } from "@shared/factories";
import { InfoStatesCollectorService, SessionStorageService, NotificationService } from '@shared/services';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  constructor(
    private infoStatesCollectorService: InfoStatesCollectorService,
    private sessionStorageService:      SessionStorageService,
    private notificationService:        NotificationService
  ) { }

  preparePDFOptions(pageSize: string){
    switch (pageSize) {
      case 'A5':
        return {
          pageSize: pageSize,

          pageMargins:        [15,  40],
          headerMargins:      [15,  20],
          tableHeaderMargins: [2.5, 2.5],
          tableMargins:       [0, 0],
          
          logoSizes: [100, 25],

          titleFontSize:      10,
          h2FontSize:         9,
          h3FontSize:         8,
          singleLineFontSize: 8,
          separatorFontSize:  5,

          mileageMoneyTableWidth: [50,'*','*',70],
          mileageMoneyTotalWidth: 150,

          photoDocumentSizes: [350, 350],
          attachmentSizes:    [350, 180],

          activityReportDetailsSummaryWidth:    150,
          activityReportDetailsTableWidth:      ['auto','auto','*',65,40,40,55],
          activityReportsListTableWidth:        [70,'auto','auto','*',45],
          activityReportsListArchiveTableWidth: [70,'auto','auto','auto',45],

          failedERPListTableWidth: [70,'auto','auto','auto',45],

          ebsDetailsTableWidth:     86,
          ebsDetailsTableWrapWidth: 20,
          ebsListTableWidth:        [70,'auto','auto','auto','*'],

          assignmentsListTableWidth: [70,'auto', 60, 80, 65],

          assignmentDetailsLeftColumnWidth:  50,
          assignmentDetailsRightColumnWidth: 70,
        };
      case 'A4':
      default:
        return {
          pageSize: pageSize,

          pageMargins:        [30, 40],
          headerMargins:      [30, 20],
          tableHeaderMargins: [5, 5],
          tableMargins:       [2.5, 2.5],

          logoSizes: [140, 40],

          titleFontSize:      13.5,
          h2FontSize:         12,
          h3FontSize:         10,
          singleLineFontSize: 10,
          separatorFontSize:  8,

          mileageMoneyTableWidth: [65,'*','*',90],
          mileageMoneyTotalWidth: 200,

          photoDocumentSizes: [500, 500],
          attachmentSizes:    [500, 300],

          activityReportDetailsSummaryWidth:    200,
          activityReportDetailsTableWidth:      ['auto','auto','*',90,55,65,80],
          activityReportsListTableWidth:        [80,'auto','auto','auto','*',90 ],
          activityReportsListArchiveTableWidth: [80,'auto','auto','auto',90],

          failedERPListTableWidth: [80,'auto','auto',60,80],


          ebsDetailsTableWidth:     110,
          ebsDetailsTableWrapWidth: 25,
          ebsListTableWidth:        [80,'auto','auto','auto','*'],

          assignmentsListTableWidth: [80,'auto', 60, 80, 65],

          assignmentDetailsLeftColumnWidth:  70,
          assignmentDetailsRightColumnWidth: 80,

          vacationRequestDetailsTableWidth: [100,'*', '*'],
          vacationRequestListTableWidth:    [100,'auto','auto',70,'*'],

          mileageMoneyListTableWidth:        [70,'auto','*',65],
          mileageMoneyArchiveListTableWidth: [70,'auto','auto','*',65,'auto']
        };
    };
  }

  async printPhotoDocumentDetails(pd: PhotoDocument, attachment: string): Promise<void> {
    await this.printPDF(
      this.createPhotoDocumentDetailsPDF,
      `Fotodokument_${pd.name}_${pd.subjectCorrected || pd.subject}`,
      pd, attachment
    );
  }

  async createPhotoDocumentDetailsPDF(pageSize: string, title: string, pd: PhotoDocument, attachment: string): Promise<PdfMakeWrapper> {
    let page = { pdf: new PdfMakeWrapper(), pdfOptions: this.preparePDFOptions(pageSize) };
    let pdf = page.pdf;
    let pdfOptions = page.pdfOptions;
    await this.prepareHeader(page, 'Fotodokument');

    this.prepareSigleLines(page,
      `Mitarbeiter: ${pd.name}`,
      `Personalnummer: ${pd.personalNumber}`,
      `Dokumenttyp: ${pd.subjectCorrected || pd.subject}`,
      `Erstellt: ${formatDate(pd.createdAt, 'dd.MM.yyyy', 'de')}`
    );
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    pdf.add(this.prepareColumns(pdfOptions, [{}, {}],
      [
        'Nachricht',
        pd.messageExternalEmployee || '-----'
      ],
      [
        'Kommentar',
        pd.commentInternalEmployee || '-----'
      ]
    ));
    pdf.add(pdf.ln(2));

    if (pd.releasedToArchiveAt) {
      let text = '';
      if (pd.archivedBySystem) text = `Automatisch archiviert am ${formatDate(pd.releasedToArchiveAt, 'dd.MM.yyyy', 'de')}`;
      else {
        text = 'Ins Archiv';
        if (pd.releasedToZvooveAt?.getTime() === pd.releasedToArchiveAt.getTime()) text += ' und nach zvoove';
        text += ' übertragen';
        if (pd.releasedToArchiveBy?.name) text += ` von ${pd.releasedToArchiveBy.name}`;
        text += ` um ${formatDate(pd.releasedToArchiveAt, 'HH:mm dd.MM.yyyy', 'de')} `;
      }
      this.prepareSigleLines(page, text);

      if (pd.releasedToZvooveAt && pd.releasedToZvooveAt?.getTime() !== pd.releasedToArchiveAt.getTime()) {
        let text = 'An zvoove übertragen';
        if (pd.releasedToZvooveBy?.name) text += ` von ${pd.releasedToZvooveBy.name}`;
        text += ` um ${formatDate(pd.releasedToZvooveAt, 'HH:mm dd.MM.yyyy', 'de')} `;

        this.prepareSigleLines(page, text);
      }
      pdf.add(pdf.ln(2));
    }

    pdf.add(await new Img(attachment).fit([pdfOptions.photoDocumentSizes[0], pdfOptions.photoDocumentSizes[1]]).alignment('center').margin([0, 0]).build());
    this.preparePDFInfo(page, title);
    return pdf;
  }

  async printActivityReportDetails(pageFormat: string, wp: ActivityReportExtended, timeFormat: string, isInternal: boolean, attachment: string, imageError: boolean): Promise<void> {
    let params: any[] = [
      this.createActivityReportDetails,
      `TN_${wp.external_employee.first_name}_${wp.external_employee.last_name}_${wp.calendar_week.replace(' ', '_')}`,
      wp, timeFormat, isInternal, attachment, imageError
    ];

    switch (pageFormat) {
      case 'A4':
        await this.printPDF(...params);
        break;
      case 'A5':
        await this.printPDFA5(...params);
        break;
      default:
        await this.printPDFPrompt(...params);
    }
  }

  async createActivityReportDetails(pageSize: string, title: string, wp: ActivityReportExtended, timeFormat: string, isInternal: boolean, attachment: string, imageError: boolean): Promise<PdfMakeWrapper> {
    let page = { pdf: new PdfMakeWrapper(), pdfOptions: this.preparePDFOptions(pageSize) };
    let pdf = page.pdf;
    let pdfOptions = page.pdfOptions;
    await this.prepareHeader(page, 'Tätigkeitsnachweis');

    this.prepareSigleLines(page,
      `Mitarbeiter: ${wp.external_employee.name}`,
      `Einsatz: ${wp.assignment.title}`,
      `Zeitraum: ${formatDate(wp.start_date, 'dd.MM.yyyy', 'de')} - ${formatDate(wp.end_date, 'dd.MM.yyyy', 'de')}`
    );
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    this.prepareTable(page, pdfOptions.activityReportDetailsTableWidth,
      this.prepareTableHeader(pdfOptions,
        '',
        'Datum',
        'Projekt',
        'Arbeitszeit',
        'Pausen',
        `Gesamts${pageSize!=='A4'?' ':''}tunden`,
        'Arbeitszeit abzgl. Pausen'
      ),
      this.prepareTableBody(pdfOptions, wp.time_frames.map(tf => [
        {text: formatDate(tf.started_at, 'EE', 'de').replace('.', '')},
        {text: formatDate(tf.started_at, 'dd.MM.yyyy', 'de')},
        {text: this.wrapWord(tf.activity_updated || tf.activity || '----', 16) },
        {text: `${formatDate(tf.startTime, 'HH:mm', 'de')} - ${formatDate(tf.endTime, 'HH:mm', 'de')} Uhr`},
        {text: !tf.pauses.length ? '--:--' : `${this.formatDate(tf.pausesDuration, timeFormat)} Std`},
        {text: `${this.formatDate(tf.endTime.getTime() - tf.startTime.getTime(), timeFormat)} Std.`},
        {text: `${this.formatDate(tf.totalDurationExcludingPauses, timeFormat)} Std.`}
      ])).map((row,index) => row.map(cell => {
        if (wp.time_frames[index].deleted) cell.decoration ='lineThrough'
        return cell;
      }))
    );

    if (wp.internal_employee_notes) {
      pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);
      this.prepareH3Title(page, 'Kommentar:');
      this.prepareSigleLines(page, wp.internal_employee_notes);
    }

    let states = this.infoStatesCollectorService.collectActivityReportStates(wp, isInternal).map(s => {
      let state = s.info;
      if (s.by) state += ` von ${s.by}`;
      if (s.at) state += ` am ${formatDate(s.at, 'dd.MM.yyyy HH:mm', 'de')}`;
      return state;
    });

    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);
    pdf.add(this.prepareColumns(pdfOptions, [{},{ width: pdfOptions.activityReportDetailsSummaryWidth }],
      [ ...states
      ].filter(l => l).map(l => new Txt(l).fontSize(pdfOptions.singleLineFontSize).end),
      [
        new Txt(`Gesamtstunden: ${this.totalWorkingTimeWithPauses(wp, timeFormat)} Std.`).fontSize(pdfOptions.singleLineFontSize).alignment('right').bold().end,
        new Txt(`Arbeitsstunden abzgl. Pausen: ${this.totalWorkingTimeWithoutPauses(wp, timeFormat)} Std.`).fontSize(pdfOptions.singleLineFontSize).alignment('right').bold().end
      ]
    ));

    if (wp.archivedBySystem) {
      pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);
      pdf.add(new Txt('Dieser Datensatz wurde automatisch archiviert, da dieser 3 Monate nicht bearbeitet wurde.').fontSize(pdfOptions.singleLineFontSize).end);
    }

    if (!imageError && wp.customer_signature) {
      pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);
      await new Img(wp.customer_signature).build().then(img => {
        pdf.add(new Table([[{ image: img.image, width: 180, height: 100 }]]).layout({defaultBorder: true}).end );
        if (wp.signer) pdf.add(new Txt(`Unterschrieben von: ${ wp.signer }`).margin([0,5]).fontSize(pdfOptions.singleLineFontSize).end);
        else pdf.add(new Txt(`Unterschrift Kunde`).margin([0,5]).fontSize(pdfOptions.singleLineFontSize).end);
      });
    }
    if (attachment) {
      pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);
      pdf.add(await new Img(attachment).fit([pdfOptions.attachmentSizes[0], pdfOptions.attachmentSizes[1]]).alignment('left').margin([0, 0]).build());
    }

    this.preparePDFInfo(page, title);
    return pdf;
  }

  async printActivityReportList(data, state: string): Promise<void> {
    await this.printPDF(
      this.createActivityReportListPDF,
      state.replace(' ', '_'),
      data
    );
  }

  async createActivityReportListPDF(pageSize: string, title: string, data): Promise<PdfMakeWrapper> {
    let page = {
      pdf: new PdfMakeWrapper(),
      pdfOptions: this.preparePDFOptions(pageSize)
    };
    let pdf = page.pdf;
    let pdfOptions = page.pdfOptions;
    await this.prepareHeader(page, data.activeTab.dashboardTitle);

    this.prepareTable(page, pdfOptions.activityReportsListTableWidth,
      this.prepareTableHeader(pdfOptions,
        'Mitarbeiter',
        data.isInternal ? 'Pers. Nr.' : '',
        'Eingang',
        data.isCustomer && (data.activeTab.dashboardCardsEnabled || +data.activeTab.filter === 1) ? 'Tempton'   : '',
        'Einsatz',
        'Zeitraum'
      ),
      this.prepareTableBody(pdfOptions, data.listItems.map((item: ActivityReportSimplified) => [
        {text: item.external_employee.name},
        {text: data.isInternal ? item.external_employee.personal_number : ''},
        {text: formatDate(item.customer_released_at || item.customer_rejected_at || item.created_at, 'dd.MM.yyyy', 'de')},
        {text: data.isCustomer &&
              (data.activeTab.dashboardCardsEnabled || +data.activeTab.filter === 1) ? 
              item.approved_at || item.archived_at ? formatDate(item.approved_at || item.archived_at, 'dd.MM.yyyy', 'de') : '-' : ''},
        {text: item.assignment.title},
        {text: item.calendar_week + ' ' + item.date.replace(' - ', '\n'), alignment: 'right'}
      ]))
    );

    this.preparePDFInfo(page, title);
    return pdf;
  }

  async printArchiveList(filteredItems: ActivityReportSimplified[]): Promise<void> {
    await this.printPDF(
      this.createArchiveListPDF,
      'Archiv',
      filteredItems
    );
  }

  async createArchiveListPDF(pageSize: string, title: string, filteredItems: ActivityReportSimplified[]): Promise<PdfMakeWrapper> {
    let page = {
      pdf: new PdfMakeWrapper(),
      pdfOptions: this.preparePDFOptions(pageSize)
    };
    let pdf = page.pdf;
    let pdfOptions = page.pdfOptions;
    await this.prepareHeader(page, 'Tätigkeitsnachweis');

    this.prepareTable(page, pdfOptions.activityReportsListArchiveTableWidth,
      this.prepareTableHeader(pdfOptions,
        'Mitarbeiter',
        'Erstellung',
        'Überprüfung',
        'Einsatz',
        'Zeitraum'
      ),
      this.prepareTableBody(pdfOptions, filteredItems.map(item => [
        {text: item.external_employee.name},
        {text: formatDate(item.created_at, 'dd.MM.yyyy', 'de')},
        {text: item.approved_at || item.archived_at ? formatDate(item.approved_at || item.archived_at, 'dd.MM.yyyy', 'de') : '-'},
        {text: item.assignment.title},
        {text: item.calendar_week + ' ' + item.date.replace(' - ', '\n'), alignment: 'right'}
      ]))
    );

    this.preparePDFInfo(page, title);
    return pdf;
  }

  async printEbsList(ebsList: Assignment[]): Promise<void> {
    await this.printPDF(
      this.createEbsListPDF,
      'EBS',
      ebsList
    );
  }

  async createEbsListPDF(pageSize: string, title: string, ebsList): Promise<PdfMakeWrapper> {
    let page = {
      pdf: new PdfMakeWrapper(),
      pdfOptions: this.preparePDFOptions(pageSize)
    };
    let pdf = page.pdf;
    let pdfOptions = page.pdfOptions;
    await this.prepareHeader(page, 'Mitarbeiter');

    this.prepareTable(page, pdfOptions.ebsListTableWidth,
      this.prepareTableHeader(pdfOptions,
        'Mitarbeiter',
        'Einspiel ID',
        'Pers. Nr.',
        'Telefon nummer',
        'Einsätze'
      ),
      this.prepareTableBody(pdfOptions, ebsList.map(item => [
        {text: item.external_employee.name},
        {text: item.ebs_data_list[0].id},
        {text: item.personal_number},
        {text: item.ebs_data_list[0].filtelefon1 ? item.ebs_data_list[0].filtelefon1 : '----', alignment: item.ebs_data_list[0].filtelefon1 ? '' : 'center'},
        {text: `${item.title} v${item.ebs_data_list[0].version}`}
      ]))
    );

    this.preparePDFInfo(page, title);
    return pdf;
  }

  async printEbsDetails(ebs: EBS): Promise<void> {
    await this.printPDF(
      this.createEbsDetailsPDF,
      'EBS',
      ebs
    );
  }

  async createEbsDetailsPDF(pageSize: string, title: string, ebs: EBS): Promise<PdfMakeWrapper> {
    let page = {
      pdf: new PdfMakeWrapper(),
      pdfOptions: this.preparePDFOptions(pageSize)
    };
    let pdf = page.pdf;
    let pdfOptions = page.pdfOptions;
    await this.prepareHeader(page, 'Einsatzbegleitscheine (EBS)');
    this.prepareSigleLines(page,
      `Mitarbeiter: ${ebs.external_name}`,
      `Job: ${ebs.aufberuf}`,
      `Einsatz: ${ebs.titleWithVersionNumber}`,
      `Einspiel ID: ${ebs.id}`,
    );
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    pdf.add(this.prepareColumns(pdfOptions, [{width: pdfOptions.ebsDetailsTableWidth}], 
      [
        new Txt('Meldeadresse:').fontSize(pdfOptions.singleLineFontSize).end,
        new Txt('Kunde:').fontSize(pdfOptions.singleLineFontSize).end,
        new Txt('Ansprechpartner Kunde:').fontSize(pdfOptions.singleLineFontSize).end,
        ''
      ],
      [
        new Txt(`${ebs.kundstrasse} ${ebs.kundort}`).fontSize(pdfOptions.singleLineFontSize).end,
        new Txt(`Ansprechpartner (${ebs.kundname1}) ${ebs.kundstrasse} ${ebs.kundplz}`).fontSize(pdfOptions.singleLineFontSize).end,
        new Txt(ebs.aufansprech1).fontSize(pdfOptions.singleLineFontSize).end,
        new Txt(ebs.auftelefon1).fontSize(pdfOptions.singleLineFontSize).end
      ],
    ));
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    pdf.add(new Columns([
      {text: 'Einsatzdaten', width: '*'},
      {text: 'Aufwandsentschädigung/Tag', width: '*'}
    ]).fontSize(pdfOptions.h2FontSize).bold().end);
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    pdf.add(this.prepareColumns(pdfOptions, [{width: pdfOptions.ebsDetailsTableWidth}, {}, {width: pdfOptions.ebsDetailsTableWidth}],
      [
        'Überlassungsbeginn:',
        'Meldeuhrzeit:',
        'Betriebsstätte:',
        'Meldeansprechpartner:',
        'Meldeansprechpartner Telefon:',
        'Meldetelefon:',
        'Einsatzberuf:',
        'Beschreibung der Einsatztätigkeit:',
        'Arbeitszeit pro Woche:',
        'Sonstige Bemerkungen:',
        '',
        '',
        '',
      ],
      [
        `${formatDate(ebs.aufueberlbeginn, 'dd.MM.yyyy', 'de')}`,
        ebs.aufmuhrzeit,
        ebs.aufbetrstaette,
        ebs.aufansprech1,
        ebs.anspr_telefon,
        this.wrapWord(ebs.auftelefon1, pdfOptions.ebsDetailsTableWrapWidth),
        this.wrapWord(ebs.aufberuf, pdfOptions.ebsDetailsTableWrapWidth),
        this.wrapWord(ebs.aufpostaet1, pdfOptions.ebsDetailsTableWrapWidth),
        ebs.aufarbzeitwoche,
        ebs.auftextmarken4,
        '',
        '',
        '',
      ],
      [
        'KM Entfernung:',
        'Fahrbetrag:',
        'VMA:',
        'Übernachtungsbetrag:',
        '',
        'Zulagen(Brutto)',
        'Entsendezulage:',
        'Sonstige Zulagen:',
        'Tarifliches',
        'Entgeltgruppe:',
        'Entgelttarif:',
        'Deckelungsbetrag:',
        'Branchenzuschlags tarifvertrag:',
        'Tarifvertrag:'
      ],
      [
        `${ebs.aufueentfernung} Km`,
        ebs.auffahrbetrag,
        ebs.aufvmabetrag,
        ebs.aufuebernbetrag,
        '',
        '',
        ebs.pel705preis,
        ebs.txt_sonstigeZulage,
        '',
        ebs.auftarifgruppe,
        ebs.auftarifmagruppe,
        ebs.aufdeckelbetragakt,
        ebs.auftarifbranche,
        this.wrapWord(ebs.auftarifvertrag, pdfOptions.ebsDetailsTableWrapWidth)
      ]
    ));
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).pageBreak('after').end);

    this.prepareH2Title(page, `Sicherheits- und Gesundheitsschutz`);
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    this.prepareSigleLines(page,
      `Gefährliche Arbeiten gemäß DGUV Regel 100-001: ${ebs.aufgefahrarbeit || 'Nein'}`,
      `Arbeitsmedizinische Untersuchungen erforderlich: ${ebs.aufuntersuchidx || 'Nein'}`,
      `Schutzausrüstung erforderlich: ${ebs.aufarbs && ebs.aufarbs.join(';') || 'Nein'}`
    );
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    this.prepareH2Title(page, 'Anmerkungen');
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    this.prepareH3Title(page, 'Sicherheits- und Gesundheitsschutz');
    this.prepareSigleLines(page,
      'Die Sicherheitsunterweisung des Mitarbeiters deckt auch die o. a. Tätigkeit ab. Der Mitarbeiter wird vor Antritt seiner Tätigkeit vom Entleiher eine Belehrung über die Sicherheitsvorschriften am Arbeitsplatz erhalten. Er verpflichtet sich, die für diesen Einsatz notwendige Schutzausrüstung an seinem Arbeitsplatz zu tragen.'
    );
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    this.prepareH3Title(page, 'Mehrarbeit');
    this.prepareSigleLines(page,
      'Der Mitarbeiter verpflichtet sich im gesetzlich zulässigen Rahmen Mehrarbeit zu leisten.'
    );
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    this.prepareH3Title(page, 'Anmerkungen (Kunden)');
    this.prepareSigleLines(page,
      `Der Mitarbeiter ist verpflichtet, Tempton unverzüglich zu informieren, wenn er mit dem oben genannten Kunden oder einem Unternehmen, das mitdiesem Kunden einen Konzern gemäß §18 AktG bildet, in den letzten sechs Monaten vor Beginn der Überlassung in einem Arbeitsverhältnis gestanden hat oder in diesem Zeitraum -egal für welchen Arbeitgeber - bereits einmal in dem nachfolgend genannten Einsatzbetrieb eingesetzt war. Bei Arbeitsverhinderung verpflichtet sich der Mitarbeiter / die Mitarbeiterin vor Arbeitsbeginn den Kunden und seine / ihre Tempton-Niederlassung (erreichbar unter der Telefonnummer: ${ebs.filtelefon1}) bis spätestens 8:30 Uhr unter Angabe der Gründe zu verständigen. Die Meldepflicht besteht auch, wenn aus irgendwelchen Gründen, die von Tempton mit dem Kunden vereinbarte Einsatzzeit nicht oder nicht voll abgeleistet werden kann, z.B. wenn der Kunde keinen oder nur einen kürzeren Einsatz ermöglicht.`
    );
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    this.prepareH3Title(page, 'Hinweis');
    this.prepareSigleLines(page,
      'Wir informieren Sie ferner gem. §11 Abs. 2 AÜG darüber, dass Sie im o. g. Einsatz als Leiharbeitnehmer (m/w/d) tätig werden.'
    );

    this.preparePDFInfo(page, title);
    return pdf;
  }

  async printAssignmentsList(assignmentList: Assignment[]): Promise<void> {
    await this.printPDF(
      this.createAssignmentsListPDF,
      'Assignments',
      assignmentList
    );
  }

  async createAssignmentsListPDF(pageSize: string, title: string, assignmentList: AssignmentExtended[]): Promise<PdfMakeWrapper> {
    let page = { pdf: new PdfMakeWrapper(), pdfOptions: this.preparePDFOptions(pageSize) };
    let pdf = page.pdf;
    let pdfOptions = page.pdfOptions;
    await this.prepareHeader(page, 'Mitarbeiter Assignments');

    this.prepareTable(page, pdfOptions.assignmentsListTableWidth,
      this.prepareTableHeader(pdfOptions,
        'Mitarbeiter',
        'Einsätze',
        'Equal Pay',
        'Höchstüber lassungsdauer',
        'Einsatzende'
      ),
      this.prepareTableBody(pdfOptions, assignmentList.map(item => [
        {text: item.external_employee.name},
        {text: item.title},
        {text: item.equal_pay         ? formatDate(item.equal_pay,         'dd.MM.yyyy', 'de') : '-'},
        {text: item.max_rental_period ? formatDate(item.max_rental_period, 'dd.MM.yyyy', 'de') : '-'},
        {text: formatDate(item.ends_at, 'dd.MM.yyyy', 'de')}
      ]))
    );

    this.preparePDFInfo(page, title);
    return pdf;
  }

  async printAssignmentExtensionPDF(assignment: AssignmentExtended, requestsList: ContractExtension[], formData): Promise<void> {
    await this.printPDF(
      this.createAssignmentExtensionPDF,
      `Mitarbeiter_Anfragen_${assignment.external_employee.first_name}_${assignment.external_employee.first_name}`,
      assignment, requestsList, formData
    );
  }

  async createAssignmentExtensionPDF(pageSize: string, title: string, assignment: AssignmentExtended, requestsList: ContractExtension[], formData): Promise<PdfMakeWrapper> {
    let page = { pdf: new PdfMakeWrapper(), pdfOptions: this.preparePDFOptions(pageSize) };
    let pdf = page.pdf;
    let pdfOptions = page.pdfOptions;
    await this.prepareHeader(page, 'Mitarbeiter anfragen');
    this.prepareSigleLines(page,
      `Mitarbeiter: ${assignment.external_employee.name}`,
      `Einsatz: ${assignment.title}`,
      `Einsatz seit: ${formatDate(assignment.starts_at, 'dd.MM.yyyy', 'de')}`,
      `Einsatz endet: ${formatDate(assignment.ends_at, 'dd.MM.yyyy', 'de')}`
    );
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    pdf.add(new Columns([
      'Einsatz verlängern bis',
      'Bitte rufen Sie mich zurück'
    ]).fontSize(pdfOptions.h2FontSize).bold().end);
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    pdf.add(this.prepareColumns(pdfOptions, [],
      [
        this.prepareColumns(pdfOptions, [{width: pdfOptions.assignmentDetailsLeftColumnWidth}], 'KW', Weeks.getWeekNumber(formData.newEndDate)),
        this.prepareColumns(pdfOptions, [{width: pdfOptions.assignmentDetailsLeftColumnWidth}], 'Datum', formatDate(assignment.starts_at, 'dd.MM.yyyy', 'de')),
      ], [
        this.prepareColumns(pdfOptions, [{width: pdfOptions.assignmentDetailsRightColumnWidth}], 'Name', formData.contactPerson.name),
        this.prepareColumns(pdfOptions, [{width: pdfOptions.assignmentDetailsRightColumnWidth}], 'Telefon', formData.contactPerson.phone || '-'),
        this.prepareColumns(pdfOptions, [{width: pdfOptions.assignmentDetailsRightColumnWidth}], 'E-mail', this.wrapWord(formData.contactPerson.email, 25)),
        this.prepareColumns(pdfOptions, [{width: pdfOptions.assignmentDetailsRightColumnWidth}], 'Erreichbar am/ab', `${formData.contactPerson.timeStart}/${formData.contactPerson.timeEnd}`)
      ]
    ));
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    if (formData.customerNote) pdf.add(this.prepareColumns(pdfOptions, [{width: pdfOptions.ebsDetailsTableWidth}], 'Nachricht an Tempton:', formData.customerNote));
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);


    if (requestsList.length) {
      this.prepareH3Title(page, 'Bisherige Anfragen');
      pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

      requestsList.map(request => {
        this.prepareSigleLines(page, `Verlängerung bis zum ${formatDate(request.contract_extension_till, 'dd.MM.yyyy', 'de')} angefragt am ${formatDate(request.created_at, 'dd.MM.yyyy', 'de')} von ${request.customer.name}`);
      });
    }

    this.preparePDFInfo(page, title);
    return pdf;
  }

  async printFailedERPList(failedERPList: ActivityReportSimplified[], form): Promise<void> {
    await this.printPDF(
      this.createFailedERPListPDF,
      'TN_Zvoove_Status',
      failedERPList, form
    );
  }

  async printVacationRequestDetails(vr: VacationRequestExtended, isInternal: boolean, attachment: string): Promise<void> {
    await this.printPDF(
      this.createVacationRequestDetails,
      `Urlaubsantrag_${vr.externalEmployee.name}_${vr.leaveType}${vr.reason ? '_' + vr.reason : ''}_${formatDate(vr.startsOn, 'dd.MM.yyyy', 'de')}_${formatDate(vr.endsOn, 'dd.MM.yyyy', 'de')}`,
      vr, isInternal, attachment
    );
  }

  async createVacationRequestDetails(pageSize: string, title: string, vr: VacationRequestExtended, isInternal: boolean, attachment: string): Promise<PdfMakeWrapper> {
    let page = { pdf: new PdfMakeWrapper(), pdfOptions: this.preparePDFOptions(pageSize) };
    let pdf = page.pdf;
    let pdfOptions = page.pdfOptions;
    await this.prepareHeader(page, 'Urlaubsantrag');

    let reason;
    if (vr.leaveType === 'overtime') reason = `Initiator: ${vr.initiatorMapped}`;
    if (vr.leaveType === 'special')  reason = `Grund: ${vr.reasonMapped}`;

    this.prepareSigleLines(page, ...[
      `Mitarbeiter: ${vr.externalEmployee.name}`,
      `Personalnummer: ${vr.externalEmployee?.personal_number}`,
      vr.assignment?.title ? `Einsatz: ${vr.assignment.title || 'Kein Einsatz vorliegend'}` : '',
      `Erstellungsdatum: ${formatDate(vr.createdAt, 'dd.MM.yyyy', 'de')}`,
       isInternal ? reason : ''
    ].filter(i => i));
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    this.prepareTable(page, pdfOptions.vacationRequestDetailsTableWidth,
      this.prepareTableHeader(pdfOptions,
        'Urlaubsart',
        'Zeitraum',
        'Anzahl',
      ),
      this.prepareTableBody(pdfOptions, [[
        { text: vr.leaveTypeMapped              },
        { text: `${vr.calendarWeek} ${vr.date}` },
        { text:  `${vr.workingVacationDays} ${vr.workingVacationDays === 1 ? 'Arbeitstag' : 'Arbeitstage'}`},
      ]])
    );

    if (vr.holidays?.length) {
      pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);
      this.prepareSigleLines(page, 'Feiertage');
      pdf.add(new Ul(vr.holidays.map(h => `${h.name}, ${formatDate(h.date, 'dd.MM.yyyy', 'de')}`)).fontSize(pdfOptions.singleLineFontSize).end);
    }

    let states = this.infoStatesCollectorService.collectVacationRequestStates(vr, isInternal).map(s => {
      let state = s.info;
      if (s.by) state += ` von ${s.by}`;
      if (s.at) state += ` am ${formatDate(s.at, 'dd.MM.yyyy HH:mm', 'de')}`;
      return state;
    });
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);
    this.prepareSigleLines(page, ...states);

    if (vr.customerRejectionReason) {
      pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);
      this.prepareSigleLines(page,
        `KD: ${vr.customerRejectionReason}`,
        vr.customerReviewComment || 'Kein Kommentar angegeben.'
      );
    }

    if (vr.internalRejectionReason) {
      pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);
      this.prepareSigleLines(page,
        `SB: ${vr.internalRejectionReason}`,
        vr.internalReviewComment || 'Kein Kommentar angegeben.'
      );
    }

    if (attachment) {
      pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);
      await new Img(attachment).build().then(img => {
        pdf.add(new Table([[{ image: img.image, width: 180, height: 100 }]]).layout({defaultBorder: true}).end );
        if (vr.signer) pdf.add(new Txt(`Unterschrieben von: ${ vr.signer }`).margin([0,5]).fontSize(pdfOptions.singleLineFontSize).end);
        else pdf.add(new Txt(`Unterschrift Kunde`).margin([0,5]).fontSize(pdfOptions.singleLineFontSize).end);
      });
    }

    this.preparePDFInfo(page, title);
    return pdf;
  }

  async printVacationRequestsList(list: VacationRequest[]): Promise<void> {
    await this.printPDF(
      this.createVacationRequestListPDF,
      'Urlaubsanträge',
      list
    );
  }
  
  async createVacationRequestListPDF(pageSize: string, title: string, listItems: VacationRequest[]): Promise<PdfMakeWrapper> {
    let page = {
      pdf: new PdfMakeWrapper(),
      pdfOptions: this.preparePDFOptions(pageSize)
    };
    let pdf = page.pdf;
    let pdfOptions = page.pdfOptions;
    await this.prepareHeader(page, 'Urlaubsanträge');

    this.prepareTable(page, pdfOptions.vacationRequestListTableWidth,
      this.prepareTableHeader(pdfOptions,
        'Mitarbeiter',
        'Pers. Nr.',
        'Eingang',
        'Urlaubsart',
        'Zeitraum'
      ),
      this.prepareTableBody(pdfOptions, listItems.map((item: VacationRequest) => [
        { text: item.externalEmployee.name                     },
        { text: item.externalEmployee.personal_number          },
        { text: formatDate(item.createdAt, 'dd.MM.yyyy', 'de') },
        { text: item.leaveTypeMapped                           },
        { text: `${item.calendarWeek} ${item.date}`            }
      ]))
    );

    this.preparePDFInfo(page, title);
    return pdf;
  }

  async printMileageMoneyList(list: StandaloneMileageMoney[] | MileageReportSimplified[], archive: boolean = null): Promise<void> {
    await this.printPDF(
      this.createMileageMoneyListPDF,
      'Kilometergeldberichte',
      list, archive
    );
  }

  async createMileageMoneyListPDF(pageSize: string, title: string, listItems: StandaloneMileageMoney[] | MileageReportSimplified[], archive: boolean = null): Promise<PdfMakeWrapper> {
    let page = {
      pdf: new PdfMakeWrapper(),
      pdfOptions: this.preparePDFOptions(pageSize)
    };
    let pdf = page.pdf;
    let pdfOptions = page.pdfOptions;
    await this.prepareHeader(page, 'Kilometergeldberichte');

    let header = [
      'Mitarbeiter',
      'Einsatz',
      'Zeitraum',
    ];

    if (archive) {
      header.splice(1, 0, 'Erstellung'   );
      header.splice(2, 0, 'Überprüfung'  );
      header.splice(6, 0, 'Typ');
    } else {
      header.splice(1, 0, 'Eingang'      );
    }

    let body = listItems.map(item => {
      let temp = [
        { text: item.externalEmployee.name                     },
        { text: formatDate(item.createdAt, 'dd.MM.yyyy', 'de') },
        { text: item.assignment.title                          },
        { text: `${item.calendarWeek} ${item.date}`            }
      ];

      if (archive) {
        temp.splice(1, 0, { text: formatDate(item.archivedAt, 'dd.MM.yyyy', 'de' )});
        temp.splice(6, 0, { text: item.resourceTypeMapped                         });
      }

      return temp;
    });

    this.prepareTable(page, archive ? pdfOptions.mileageMoneyArchiveListTableWidth : pdfOptions.mileageMoneyListTableWidth,
      this.prepareTableHeader(pdfOptions, ...header),
      this.prepareTableBody(pdfOptions, body)
    );

    this.preparePDFInfo(page, title);
    return pdf;
  }

  async printMileageMoneyDetails(mm: ActivityReportMileageMoneyExtended | StandaloneMileageMoney, params?): Promise<void> {
    await this.printPDF(
      this.createMileageMoneyDetailsPDF,
      `KG_${mm.externalEmployee.first_name}_${mm.externalEmployee.last_name}_${mm.calendarWeek.replace(' ', '_')}`,
      mm, params
    );
  }

  async createMileageMoneyDetailsPDF(pageSize: string, title: string, mm: ActivityReportMileageMoneyExtended | StandaloneMileageMoney, { signature, event, updatedBy }): Promise<PdfMakeWrapper> {
    let page = { pdf: new PdfMakeWrapper(), pdfOptions: this.preparePDFOptions(pageSize) };
    let pdf = page.pdf;
    let pdfOptions = page.pdfOptions;
    await this.prepareHeader(page, 'Kilometergeldabrechnung');

    let header = [
      `Mitarbeiter: ${mm.externalEmployee.name}`,
      `Personalnummer: ${mm.externalEmployee.personal_number}`,
      `Zeitraum: ${formatDate(mm.startDate, 'dd.MM.yyyy', 'de')} - ${formatDate(mm.endDate, 'dd.MM.yyyy', 'de')}`,
      `Einsatz: ${mm.assignment.title}`,
      `Kalenderwoche: ${mm.calendarWeek}`
    ];

    if ((mm as ActivityReportMileageMoneyExtended).licensePlateMapped) header.push(`Kennzeichen: ${(mm as ActivityReportMileageMoneyExtended).licensePlateMapped}`);

    this.prepareSigleLines(page, ...header);
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);

    this.prepareTable(page, pdfOptions.mileageMoneyTableWidth,
      this.prepareTableHeader(pdfOptions,
        'Datum',
        'Von',
        'Nach',
        'Entfernung in Km'
      ),
      this.prepareTableBody(pdfOptions, mm.workDays.map((wd: StandaloneMileageMoneyDaily | ActivityReportMileageMoneyDaily) => [
        { text: formatDate((wd as ActivityReportMileageMoneyDaily).startedAt || (wd as StandaloneMileageMoneyDaily).date, 'dd.MM.yyyy', 'de') },
        { text: wd.externalEmployeeAddress                },
        { text: wd.assignmentAddress                      },
        { text: `${wd.amountOfKm} Km`, alignment: 'right' }
      ])).map((row, index) => row.map(cell => {
        if (mm.workDays[index].deleted) cell.decoration = 'lineThrough';
        return cell;
      }))
    );
    pdf.add(pdf.ln(1));

    let totalKmLine      = `Gesamte Strecke: ${mm.totalKm} Km`;
    let totalSumLine     = `Gesamter Betrag: ${mm.totalSum} €`;
    let corectedTotalSum = `korrigierter Gesamtbetrag: ${mm.customCalculatedSum} €`;

    let corectedVersion = this.infoStatesCollectorService.mapMilegeMoneyVersionHistoryItem(Object.assign(mm, {event}, {updatedBy}));

    pdf.add(this.prepareColumns(pdfOptions, [{},{width: pdfOptions.mileageMoneyTotalWidth}],
      [ new Txt(corectedVersion).fontSize(pdfOptions.singleLineFontSize).bold().end ],
      [
        new Txt(totalKmLine).fontSize(pdfOptions.singleLineFontSize).decoration(mm.customCalculatedSum  ? 'lineThrough' : null).alignment('right').bold().end,
        new Txt(totalSumLine).fontSize(pdfOptions.singleLineFontSize).decoration(mm.customCalculatedSum ? 'lineThrough' : null).alignment('right').bold().end,
        mm.customCalculatedSum ? new Txt(corectedTotalSum).fontSize(pdfOptions.singleLineFontSize).alignment('right').bold().end : ''
      ]
    ));

    if (mm.internalNote) {
      pdf.add(pdf.ln(1));
      pdf.add(new Txt('Kommentar:').fontSize(pdfOptions.singleLineFontSize).bold().end);
      pdf.add(new Txt(mm.internalNote).fontSize(pdfOptions.singleLineFontSize).end);
    }

    if (signature) {
      pdf.add(pdf.ln(1));
      await new Img(signature).build().then(img => {
        pdf.add(new Table([[{ image: img.image, width: 180, height: 100 }]]).layout({defaultBorder: true}).end );
        pdf.add(new Txt(`Unterschrift Mitarbeiter`).margin([0,5]).fontSize(pdfOptions.singleLineFontSize).end);
      });
    }

    this.preparePDFInfo(page, title);
    return pdf;
  }

  private getFromToString(form): string {
    let string: string = 'Zeitraum: ';
    if (form.from) string = string + formatDate(form.from, 'dd.MM.yyyy', 'de');
    if (form.from && !form.to) string = `${string} bis heute`;
    if (form.to && !form.from) string = `${string} alles`;
    if (form.to) string = `${string} bis ${formatDate(form.to, 'dd.MM.yyyy', 'de')}`;
    return string;
  }

  async createFailedERPListPDF(pageSize: string, title: string, FailedERPList: ActivityReportSimplified[], form): Promise<PdfMakeWrapper> {
    let page = { pdf: new PdfMakeWrapper(), pdfOptions: this.preparePDFOptions(pageSize) };
    let pdf = page.pdf;
    let pdfOptions = page.pdfOptions;
    await this.prepareHeader(page, 'Zvoove Status Übersicht');
    if (form.from || form.to) {
      this.prepareSigleLines(page, this.getFromToString(form));
      pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);
    }

    this.prepareTable(page, pdfOptions.failedERPListTableWidth,
      this.prepareTableHeader(pdfOptions,
        'Mitarbeiter',
        'Eingang',
        'Einsatz',
        'Zeitraum',
        'Status'
      ),
      this.prepareTableBody(pdfOptions, FailedERPList.map(item => [
        {text: item.external_employee.name},
        {text: formatDate(item.approved_at, 'dd.MM.yyyy', 'de')},
        {text: item.assignment.title},
        {text: item.calendar_week + ' ' + item.date.replace(' - ', '\n')},
        {text: item.export_state === 'failed_export' ? 'Fehlerhaft' : 'Manuell erledigt', alignment: 'right'}
      ]))
    );

    this.preparePDFInfo(page, title);
    return pdf;
  }

  async prepareHeader({pdf, pdfOptions}, title: string): Promise<void> {
    pdf.header(new Txt('Tempton Personaldienstleistungen GmbH | Schürmannstraße 24 | 45136 Essen').alignment('right').margin(pdfOptions.headerMargins).fontSize(8).end);
    pdf.add(this.prepareColumns(pdfOptions, [{width: '*'},{width: pdfOptions.logoSizes[0]}],
      [new Txt(`Tempton Personaldienstleistungen GmbH ${title}`).alignment('left').fontSize(pdfOptions.titleFontSize).bold().end],
      [await new Img('/assets/images/tempton-logo-blue.png').fit(pdfOptions.logoSizes).alignment('right').build()]
    ));
    pdf.add(new Txt(' ').fontSize(pdfOptions.separatorFontSize).end);
  }

  private prepareLines(pdf: PdfMakeWrapper, options: any, ...lines: string[]): void {
    lines.forEach(line => {
      let text = new Txt(line);
      if (options) {
        if (options.fontSize) text.fontSize(options.fontSize);
        if (options.alignment) text.alignment(options.alignment);
        if (options.decoration) text.decoration(options.decoration);
        if (options.pageBreak) text.pageBreak(options.pageBreak);
        if (options.bold) text.bold();
      }
      pdf.add(text.end);
    });
  }

  private prepareSigleLines({pdf, pdfOptions}, ...lines: string[]): void {
    this.prepareLines(pdf, {fontSize: pdfOptions.singleLineFontSize}, ...lines);
  }

  private prepareH2Title({pdf, pdfOptions}, ...lines: string[]): void {
    this.prepareLines(pdf, {fontSize: pdfOptions.h2FontSize, bold: true}, ...lines);
  }

  private prepareH3Title({pdf, pdfOptions}, ...lines: string[]): void {
    this.prepareLines(pdf, {fontSize: pdfOptions.h3FontSize, bold: true}, ...lines);
  }

  private prepareH3TitleRightAlign(pdf: PdfMakeWrapper, ...lines: string[]): void {
    this.prepareLines(pdf, {fontSize: 10, bold: true, alignment: 'right'}, ...lines);
  }

  private prepareH3TitleRightAlignCrossed(pdf: PdfMakeWrapper, ...lines: string[]): void {
    this.prepareLines(pdf, {fontSize: 10, bold: true, alignment: 'right', decoration: 'lineThrough'}, ...lines);
  }

  private prepareColumns(pdfOptions, columnOptions, ...columns) {
    columns = columns.map(c => Array.isArray(c) ? c : new Array(c.toString()));
    let temp = [];
    for (let i = 0; i < Math.max(...columns.map(c => c.length)); i++ ) {
      temp.push(new Columns([...columns.map((column, index) => {
        if (Array.isArray(column[i])) return column[i];
        else {
          let cell = {text: ''};
          if (column[i] && column[i].image) return column[i];
          else cell.text = column[i];
          if (columnOptions && columnOptions[index]) for (const key in columnOptions[index]) cell[key] = columnOptions[index][key]; 
          return cell;
        }
      })]).fontSize(pdfOptions.singleLineFontSize).columnGap(10).end );
    }
    return temp;
  }

  private prepareTableHeader(pdfOptions, ...titles: string[]): any[] {
    let header: any = titles.map(t => ({text: t}));
    header = header.map(item => {
      item.color = '#0099A7';
      item.fillColor = '#F0F0F0';
      item.borderColor = ['#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0'];
      item.margin = pdfOptions.tableHeaderMargins;
      return item;
    });
    return header;
  }

  private prepareTableBody(pdfOptions, dataList): any[] {
    return dataList.map(item => {
      if (Array.isArray(item)) return this.prepareTableBody(pdfOptions, item);
      item.margin = pdfOptions.tableMargins;
      item.borderColor = ['#fff', '#fff', '#fff', '#ddd'];
      item.fontSize = pdfOptions.singleLineFontSize;
      return item;
    });
  }

  private prepareTable({pdf, pdfOptions}, widths: (string|number)[], header, tableItems = []): void {
    pdf.add(new Table([
      header,
      ...tableItems
    ]).widths(widths).dontBreakRows(true).fontSize(pdfOptions.singleLineFontSize).layout('lightHorizontalLines').end);
  }

  private preparePDFInfo({pdf, pdfOptions}, title: string): void {
    pdf.pageSize(pdfOptions.pageSize);
    pdf.pageMargins(pdfOptions.pageMargins);
    pdf.info({
      title: this.preparePDFName(title),
    });
  }

  private preparePDFName(title: string): string {
    return `Tempton_${title}_${formatDate(new Date, 'dd.MM.yyyy', 'de')}.pdf`;
  }

  private async printPDF(pdfHandler?: Function, title?: string, ...params): Promise<void> {
    if (window.document['documentMode']) return this.notificationService.alert('Druckfunktionalität ist für Internet Explorer 11 nicht verfügbar');
    this.printPDFFormat('A4', pdfHandler, title, ...params);
  }

  private async printPDFA5(pdfHandler?: Function, title?: string, ...params): Promise<void> {
    if (window.document['documentMode']) return this.notificationService.alert('Druckfunktionalität ist für Internet Explorer 11 nicht verfügbar');
    this.printPDFFormat('A5', pdfHandler, title, ...params);
  }

  private async printPDFPrompt(pdfHandler?: Function, title?: string, ...params): Promise<void> {
    if (window.document['documentMode']) return this.notificationService.alert('Druckfunktionalität ist für Internet Explorer 11 nicht verfügbar');
    this.notificationService.optionalPrompt(
      'Notiz',
      'Welches Format möchten Sie drucken ?',
      'Ausgewähltes Format als Standard festlegen',
      () => localStorage.setItem('saveDefaultPrintWPDetails', `${+!+localStorage.getItem('saveDefaultPrintWPDetails')}`),
      { left: 'A4', right: 'A5' },
      () => {
        if (localStorage.getItem('saveDefaultPrintWPDetails')) this.sessionStorageService.updateUserSettings('A4', 'printOptions', 'wpDetails', 'pageSize')
        this.printPDFFormat('A4', pdfHandler, title, ...params);
      },
      () => {
        if (localStorage.getItem('saveDefaultPrintWPDetails')) this.sessionStorageService.updateUserSettings('A5', 'printOptions', 'wpDetails', 'pageSize')
        this.printPDFFormat('A5', pdfHandler, title, ...params);
      }
    );
  }

  private async printPDFFormat(pageSize: string = 'A4', pdfHandler: Function, title: string, ...params) {
    this.notificationService.wait();
    let pdf = await pdfHandler.call(this, pageSize, title, ...params);
    if (window.navigator && ((window.navigator as any).msSaveOrOpenBlob || navigator.userAgent.indexOf("Firefox") != -1)) {
      pdf.create().download(this.preparePDFName(title));
    } else pdf.create().print();
    this.notificationService.close();
  }

  private wrapWord(str: string, length: number): string[] {
    return str.match(new RegExp(`.{1,${length}}`, 'g'));
  }

  private formatDate(date, timeFormat) {
    if (timeFormat === 'industrial') return this.formatDateIndustrial(date);
    else if (timeFormat === 'real-time') return this.formatDateRealTime(date);
  }

  private formatDateIndustrial(duration: number): string {
    if (duration < 0) return '0.0';
    let hours = Number(duration / 60 / 60 / 1000).toFixed(2);
    return +hours.substr(-1) ? hours : Number(hours).toFixed(1);
  }

  private formatDateRealTime(duration: number): number|string {
    if (duration < 0) return '00:00';
    const hours = Math.floor(duration / 60 / 60 / 1000);
    const minutes = (duration - (hours * 60 * 60 * 1000)) / 60 / 1000;
    return `${!hours ? '00' : hours < 10 ? '0' + hours : hours}:${!minutes ? '00' : minutes < 10 ? '0' + minutes : minutes}`;
  }

  private totalWorkingTimeWithoutPauses(wp, timeFormat): string|number {
    if (wp) return this.formatDate(wp.arTimeWithoutPauses, timeFormat);
    return '--:--';
  }

  private totalWorkingTimeWithPauses(wp, timeFormat): string|number {
    if (wp) return this.formatDate(wp.arTimeWithPauses, timeFormat);
    return '--:--';
  }

}
